<template>
  <div class="w-100">
    <h1 class="mb-4">Create meta data</h1>

    <div class="form">
      <label for="">Type:</label>
      <div class="form-row">
        <div class="form-group col-lg-6">
          <select
            v-model="subjectType"
            class="form-control"
          >
            <option
              v-for="type in subjectTypes"
              :value="type"
              v-bind:key="type"
            >
              {{ type.title }}
            </option>
          </select>
        </div>
      </div>

      <div v-if="this.subjectType.title === 'products'">
        <label for="">Select product city:</label>
          <div slot="header">
            <v-select v-model="selectedCities" @search="citySearch" :options="searchedCities" label="title" multiple
                      :filterable="false" placeholder="search ..." class="form-group col-lg-6">
              <template slot="no-options">
                type to search cities...
              </template>
              <template slot="option" slot-scope="option">
                <div>{{ option.id + ' - '+ option.name + ' (' + option.country_name + ') '}}</div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div style="">{{ option.id + ' - '+ option.name + ' (' + option.country_name + ') '}}</div>
              </template>
            </v-select>
          </div>

        <label for="">Select Product:</label>
          <div slot="header" class="form-row">
            <v-select v-model="subject" @search="fetchProducts"  @input="setCreateSelected" :options="options" label="title"
                      :filterable="false" placeholder="search ..." class="form-group col-lg-6">
              <template slot="no-options">
                type to search products...
              </template>
              <template slot="option" slot-scope="option">
                <div>{{ option.id + ' - '+ option.title + ' - ' + option.location?.city.name }}</div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div style="">{{ option.id + ' - '+ option.title + ' - ' + option.location?.city.name }}</div>
              </template>
            </v-select>
          </div>
      </div>

      <div v-if="this.subjectType.title === 'home' || (this.subjectType.title === 'products' && this.subjectId != null)" class="mb-4">
        <button class="btn btn-primary" @click ="generateMetaData">Generate metadata with AI</button>

        <div v-if="metaDataLoading">Generating metadata with chat gpt...</div>
      </div>

      <label for="">Meta title:</label>
      <div class="form-row">
        <div class="form-group col-lg-6">
          <input
            v-model="form.title"
            class="form-control"
            placeholder="Title"
          />
        </div>
      </div>

      <label for="">Meta description:</label>
      <div class="form-row">
        <div class="form-group col-lg-6">
          <textarea
            v-model="form.description"
            class="form-control"
            placeholder="Description"
          />
        </div>
      </div>

      <ul style="padding-left: 20px; margin-top: 20px">
        <li v-for="error in errors" v-bind:key="error" class="text-danger">
          {{ error }}
        </li>
      </ul>

      <button type="submit" class="btn btn-success pl-4 pr-4" @click="submit()">
        Create
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {config} from "../../helpers";
import metadataOptions from "@/static/metadata.json"
import VueSelect from "vue-select";

export default {
  name: "MetaDataCreate",
  components: {
    'v-select': VueSelect,
  },
  data() {
    return {
      subjectTypes: metadataOptions,

      options: [],
      cities: [],
      searchedCities: [],
      selectedCities: [],
      selectedCityIds: [],

      subjectType: {title: null},
      subject: null,
      subjectId: null,

      metaDataLoading: false,

      form: {
        title: null,
        description: null,
      },
      errors: [],
      debouncedFetchProducts : _.debounce((search, loading) => {
        loading(true);
        this.selectedCityIds = this.selectedCities.map(city => city.id).join('|');

        const params = {};

        params['filter[title]'] = search;
        params['available'] = 1;

        if(this.selectedCityIds){
          params['filter[city][ids]'] = this.selectedCityIds
        }

        axios.get(config("BACKEND_ROOT") + "/admin/products", {params: params}).then((response) => {
          this.options = response.data.data;
          loading(false);
        });
      }, 350), // Set a delay of 350 milliseconds
      debouncedCitySearch : _.debounce((q, loading) => {
        loading(true);
        axios.get(`${config("BACKEND_ROOT")}/admin/cities?q=${q}`).then((response) => {
          this.searchedCities = response.data.data;
          loading(false);
        });
      }, 350), // Set a delay of 350 milliseconds
    };
  },

  methods: {
    fetchProducts(search, loading) {
      this.debouncedFetchProducts(search, loading);
    },

    citySearch(q, loading) {
      this.debouncedCitySearch(q, loading);
    },

    async generateMetaData() {
      this.metaDataLoading = true;

        var type = 'products';
        if (this.subjectType.title === 'home'){
          var id = 0;
        }else{
          id = this.subjectId;
        }

        axios.post(`${config("BACKEND_ROOT")}/admin/meta-data/${type}/${id}/ai`).then((response) => {
          var res = response.data.data;
          this.form.title = res.title;
          this.form.description = res.description;
          this.metaDataLoading = false;
        }).catch((error) => {
          console.error('Error:', error);
        });
    },

    setCreateSelected(value)
    {
      this.subjectId = value?.id;
    },

    submit() {
      if (this.subjectType.title === 'home'){
        this.subjectId = 0;
        this.subjectType.title = 'products'
      }

      axios
        .post(
          `${config("BACKEND_ROOT")}/admin/meta-data/${this.subjectType.title}/${
            this.subjectId
          }`,
          this.form
        )
        .then((response) => {
          this.$router.push("/meta-data");
        })
        .catch((error) => {
          this.errors = Object.values(error.response.data.errors).flat();
        });
    },
  },
};
</script>
