<template>
  <div class="p-4">
    <button @click="$modal.hide('edit-tags-modal')" class="modal-close-button">
      ❌
    </button>

    <div class="h4 mb-4">Select tags</div>
    <div class="mb-2">
      <h5>Occaisions</h5>
      <div class="bg-gray p3 mb-4">
        <div class="list-group w-100 d-inline-block mt-2">
          <label
            v-for="tag in tags.filter((el) => el.type == 'occasion')"
            v-bind:key="tag.id"
            class="list-group-item pl-5 w-50 d-inline-block"
          >
            <input
              v-model="selectedTags"
              type="checkbox"
              class="form-check-input"
              :value="tag.id"
            />
            {{ tag.title }}
          </label>
        </div>
      </div>

      <h5>Recipients</h5>
      <div class="bg-gray p3 mb-4">
        <div class="list-group w-100 d-inline-block mt-2">
          <label
            v-for="tag in tags.filter((el) => el.type == 'recipient')"
            v-bind:key="tag.id"
            class="list-group-item pl-5 w-50 d-inline-block"
          >
            <input
              v-model="selectedTags"
              type="checkbox"
              class="form-check-input"
              :value="tag.id"
            />
            {{ tag.title }}
          </label>
        </div>
      </div>

      <h5>Events</h5>
      <div class="bg-gray p3 mb-4">
        <div class="list-group w-100 d-inline-block mt-2">
          <label
            v-for="tag in tags.filter((el) => el.type == 'event')"
            v-bind:key="tag.id"
            class="list-group-item pl-5 w-50 d-inline-block"
          >
            <input
              v-model="selectedTags"
              type="checkbox"
              class="form-check-input"
              :value="tag.id"
            />
            {{ tag.title }}
          </label>
        </div>
      </div>
    </div>
    <button class="btn btn-success float-right mb-3" @click="editTags">
      Done
    </button>
  </div>
</template>

<script>
import { config } from "../../../helpers";

export default {
  props: ["tags", "etOldTags", "etSelectedTags", "etSelectedExperience"],

  data() {
    return {
      oldTags: this.etOldTags,
      selectedTags: this.etSelectedTags,
      selectedExperience: this.etSelectedExperience,
    };
  },

  methods: {
    editTags() {
      let newTags = this.selectedTags.filter(
        (el) => !this.oldTags.includes(el)
      );

      let removedTags = this.oldTags.filter(
        (el) => !this.selectedTags.includes(el)
      );

      axios
        .put(config("BACKEND_ROOT") + "/admin/product-tag", {
          product_id: this.selectedExperience,
          new_tag_ids: newTags,
          removed_tag_ids: removedTags,
        })
        .then((response) => {
          this.$modal.hide("edit-tags-modal");
          this.$emit("done");
        });
    },
  },
};
</script>

<style scoped>
.abExperiencesList {
  max-height: 700px;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>