<template>
  <div>
    <div class="mb-2 mt-3">
      <label for="formFile" class="form-label">{{ label }}</label>
      <input
          class="form-control"
          type="file"
          id="formFile"
          @change="upload"
          ref="uploader"
          multiple
      />
    </div>

    <div class="progress" v-if="fileSelected && uploadedPhotos.length > 0">
      <div
          :class="'progress-bar progress-bar-striped ' + progressClass"
          role="progressbar"
          :style="'width: ' + progressValue + '%'"
          :aria-valuenow="progressValue"
          aria-valuemin="0"
          aria-valuemax="100"
      ></div>
    </div>

    <div class="uploaded-images" v-if="uploadedPhotos.length > 0">
      <div class="uploaded-image" v-for="photo of uploadedPhotos" v-bind:key="photo.fileName">
        <img :src="photo.url"/>
        <i class="fa fa-trash" @click="remove(photo.fileName)"></i>
      </div>
    </div>
  </div>
</template>

<script>
import {config} from "../helpers";

export default {
  props: ["label", "photos"],

  data() {
    return {
      uploadedPhotos: [],

      selectedFiles: [],
      fileName: null,

      fileSelected: false,
      progressClass: "",
      progressValue: 20,
    };
  },

  mounted() {
    if (this.photos) {
      for (let photo of this.photos) {
        this.uploadedPhotos.push({
          fileName: "files" + photo.url.substring(photo.url.lastIndexOf('/')),
          url: photo.url
        })
      }
    } else {
      this.uploadedPhotos = []
    }
  },

  methods: {
    upload() {
      this.selectedFiles = Array.from(this.$refs.uploader.files);

      // this.photo = null; THINK
      this.fileSelected = true; // THINK
      this.progressClass = "";
      this.progressValue = 20;

      const headers = {"Content-Type": "multipart/form-data"};

      const uploadPromises = this.selectedFiles.map((selectedFile) => {
        const formData = new FormData();
        formData.append("file", selectedFile);
        formData.append("type", selectedFile.type.split('/')[0]);

        return axios
            .post(config("BACKEND_ROOT") + "/admin/uploads", formData, {headers})
            .then((res) => {
              this.uploadedPhotos.push({
                fileName: res.data.data.file_name,
                url: res.data.data.url
              })
              this.progressClass = "bg-success";
              this.progressValue = 100;
            })
            .catch((error) => {
              this.progressClass = "bg-danger";
              this.progressValue = 100;
              alert(error.response.data.errors.file[0]);
            });
      });
      Promise.all(uploadPromises)
          .then(() => {
            this.$emit("done", this.uploadedPhotos.map(photo => photo.fileName));
          }).catch((error) => {
        console.error("Error uploading files:", error);
      });
    },

    remove(fileName) {
      axios.delete(config("BACKEND_ROOT") + "/admin/uploads", {data: {file_name: fileName}})
          .then(res => {

          })
          .catch(error => {
            alert(error.response.data.errors.file[0]);
          })

      this.uploadedPhotos = this.uploadedPhotos.filter(photo => photo.fileName != fileName);
      this.$emit("done", this.uploadedPhotos.map(photo => photo.fileName));
    }
  },
};
</script>

<style scoped>
.uploaded-images {
  width: 100%;
  overflow: hidden;
  margin-top: 10px;
}
.uploaded-image {
  position: relative;
  display: inline-block;
  height: 80px;
  margin-right: 10px;
}
.uploaded-image img {
  height: 100%;
  border-radius: 4px;
}
.uploaded-image i {
  position: absolute;
  color: red;
  top: 10px;
  left: 10px;
  cursor: pointer;
}
</style>