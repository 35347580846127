<template>
  <div class="p-4">
    <button @click="$modal.hide('logs-modal')" class="modal-close-button">
      ❌
    </button>

    <div class="h3 mb-2">Logs</div>

    <div v-if="loaded">
      <div v-show="logs.length === 0" class="font-italic">No logs found.</div>

      <ul id="logs" class="p-0">
        <li
          :class="
            'list-group-item p-2 pl-3 pr-3 mb-2 rounded ' +
            getClassForStatus(log.data.status)
          "
          v-for="log of logs"
          v-bind:key="log.id"
        >
          <span class="font-weight-light">{{ log.data.message }}</span>
          <span class="font-weight-light font-italic log-time"
            >({{ formatDate(log.created_at) }})</span
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { config } from "../../../helpers";

export default {
  props: ["id"],

  data() {
    return {
      loaded: false,
      logs: [],
    };
  },

  mounted() {
    this.url = config("BACKEND_ROOT") + "/admin/logs";
    this.loadData();
  },

  methods: {
    loadData() {
      axios
        .get(`${this.url}?model=cart&id=${this.id}&per_page=100`)
        .then((response) => {
          this.logs = response.data.data;
          this.logs = [];
          this.loaded = true;
        });
    },

    getClassForStatus(status) {
      return {
        ok: "list-group-item-light",
        warning: "list-group-item-warning",
        error: "list-group-item-danger",
      }[status];
    },

    formatDate(date) {
      if (!date) return null;

      date = new Date(date);

      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes()
      );
    },
  },
};
</script>

<style scoped>
.log-time {
  color: #555;
  font-size: 12px;
  margin-left: 5px;
}
</style>
