<template>
  <div class="w-100 mt-2">
    <h1 class="d-inline">Collections List</h1>
    <button class="btn btn-success float-right" @click="$router.push('/collections/create')">Create a collection</button>

    <div class="table-responsive">
      <table class="table mt-4">
        <thead class="thead-light">
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Name</th>
            <th scope="col">Description</th>
            <th scope="col">Status</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" v-bind:key="item.id" style="cursor:pointer;">
            <td>{{ item.id }}</td>
            <td>{{ item.title }}</td>
            <td>{{ item.short_description.substr(0, 100) + '...' }}</td>
            <td v-html="getIsPublishedForHuman(item.is_published)"></td>
            <td>
              <button class="btn btn-sm btn-primary" title="Edit" @click="$router.push(`/collections/${item.id}`)">
                <i class="fa fa-edit"></i>
              </button>
              <button class="btn btn-sm btn-danger ml-2" title="Delete" @click="deleteCollection(item)">
                <i class="fa fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { config } from "../helpers";

export default {
  name: "Gifts",
  data() {
    return {
      url: "",
      items: [],
    };
  },

  mounted() {
    this.url = config("BACKEND_ROOT") + "/admin/collections";
    this.loadData();
  },

  methods: {
    loadData() {
      axios.get(this.url).then((response) => {
        this.items = response.data.data;
      });
    },

    deleteCollection(collection) {
      if (confirm(`Do you want to delete ${collection.title}?`)) {
        axios.delete(`${this.url}/${collection.id}`);
        this.loadData();
      }
    },

    getIsPublishedForHuman(isPublished) {
      return `<div class="text ${isPublished ? 'text-success' : 'text-warning'}">${isPublished ? 'Published' : 'Draft'}</div>`;
    },
  },
};
</script>

<style scoped>
.table {
  font-size: 14px !important;
}
</style>