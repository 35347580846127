<template>
  <div class="p-4">
    <button
      @click="$modal.hide('edit-blackout-dates-modal')"
      class="modal-close-button"
    >
      ❌
    </button>

      <div class="h4 mb-4">Select blackout dates</div>
      <div class="atCategoriesList pl-3">
        <div class="row">
          <div class="list-group w-100 d-inline-block mt-2">
            <label
              v-for="blackoutDate in blackoutDates"
              v-bind:key="blackoutDate.id"
              :class="
                (blackoutDate.is_active ? '' : 'text-danger') +
                ' list-group-item pl-5 w-50 d-inline-block'
              "
            >
              <input
                v-model="selectedBlackoutDates"
                class="form-check-input me-1"
                type="checkbox"
                :value="blackoutDate.id"
              />
              {{ blackoutDate.title }}
            </label>
          </div>
        </div>
      </div>
      <button class="btn btn-success float-right mb-3" @click="editBlackoutDates">
        Done
      </button>
  </div>
</template>

<script>
import { config } from "../../../helpers";

export default {
  props: ["blackoutDates", "productId", "blackoutDateIds"],
  data() {
    return {
      selectedBlackoutDates: [],
    };
  },

  mounted() {
    this.selectedBlackoutDates = this.blackoutDateIds;
  },

  methods: {
    editBlackoutDates() {
      axios
        .put(`${config("BACKEND_ROOT")}/admin/products/${this.productId}/blackoutdates`, {
          blackout_date_ids: this.selectedBlackoutDates,
        })
        .then((response) => {
          this.$emit("done");
        });
    },
  },
};
</script>