<template>
  <div class="p-4">
    <button @click="$modal.hide('add-tag-modal')" class="modal-close-button">
      ❌
    </button>
    <div v-if="atStep == 1">
      <div class="h4 mb-4">Select categories</div>
      <div class="atCategoriesList pl-3">
        <div class="row">
          <div class="list-group w-100 d-inline-block mt-2">
            <label
              v-for="category in categories"
              v-bind:key="category.id"
              class="list-group-item pl-5 w-50 d-inline-block"
            >
              <input
                v-model="atSelectedCategory"
                class="form-check-input me-1"
                type="radio"
                :value="category.id"
              />
              {{ category.title }}
            </label>
          </div>
        </div>
      </div>
      <button class="btn btn-primary float-right mb-3" @click="atGoToStep2">
        Next »
      </button>
    </div>

    <div v-if="atStep == 2">
      <div class="h4 mb-3">Select experiences</div>
      <div class="atExperiencesList mb-3">
        <div class="row">
          <div class="list-group w-100 d-inline-block mt-2">
            <label
              v-for="experience in atExperiences"
              v-bind:key="experience.id"
              class="list-group-item pl-5 w-50 d-inline-block"
            >
              <input
                v-model="atSelectedExperiences"
                class="form-check-input me-1"
                type="checkbox"
                @change="checkExperienceCheckbox($event)"
                :value="experience.id"
                :placeholder="experience.title"
              />
              {{ experience.title }}
            </label>
          </div>
        </div>
      </div>

      <div class="mb-4">
        <b-badge
          href="#"
          v-for="experience in atSelectedExperiencesChips"
          v-bind:key="experience.id"
          variant="primary"
          class="mr-2"
          @click="checkExperienceChip($event)"
          :placeholder="experience.id"
          >{{ experience.title }} x</b-badge
        >
      </div>

      <button
        class="btn btn-primary float-left mb-3"
        @click="
          atStep--;
          atSelectedCategory = null;
          atSelectedExperiences = [];
          atSelectedExperiencesChips = [];
        "
      >
        « Previous
      </button>
      <button class="btn btn-primary float-right mb-3" @click="atStep++" :disabled="atSelectedExperiences.length === 0">
        Next »
      </button>
    </div>

    <div v-if="atStep == 3">
      <div class="h4 mb-4">Select tags</div>
      <div class="mb-2">
        <h5>Occaisions</h5>
        <div class="bg-gray p3 mb-4">
          <div class="list-group w-100 d-inline-block mt-2">
            <label
              v-for="tag in tags.filter((el) => el.type == 'occasion')"
              v-bind:key="tag.id"
              class="list-group-item pl-5 w-50 d-inline-block"
            >
              <input
                v-model="atSelectedTags"
                type="checkbox"
                class="form-check-input"
                :value="tag.id"
              />
              {{ tag.title }}
            </label>
          </div>
        </div>

        <h5>Recipients</h5>
        <div class="bg-gray p3 mb-4">
          <div class="list-group w-100 d-inline-block mt-2">
            <label
              v-for="tag in tags.filter((el) => el.type == 'recipient')"
              v-bind:key="tag.id"
              class="list-group-item pl-5 w-50 d-inline-block"
            >
              <input
                v-model="atSelectedTags"
                type="checkbox"
                class="form-check-input"
                :value="tag.id"
              />
              {{ tag.title }}
            </label>
          </div>
        </div>

        <h5>Events</h5>
        <div class="bg-gray p3 mb-4">
          <div class="list-group w-100 d-inline-block mt-2">
            <label
              v-for="tag in tags.filter((el) => el.type == 'event')"
              v-bind:key="tag.id"
              class="list-group-item pl-5 w-50 d-inline-block"
            >
              <input
                v-model="atSelectedTags"
                type="checkbox"
                class="form-check-input"
                :value="tag.id"
              />
              {{ tag.title }}
            </label>
          </div>
        </div>
      </div>
      <button class="btn btn-primary float-left mb-3" @click="atStep--">
        « Previous
      </button>
      <button class="btn btn-success float-right mb-3" @click="addTag">
        Done
      </button>
    </div>
  </div>
</template>

<script>
import { config } from "../../../helpers";

export default {
  props: ["categories", "tags"],

  data() {
    return {
      atStep: 1,
      atSelectedCategory: null,
      atExperiences: [],
      atSelectedExperiences: [],
      atSelectedExperiencesChips: [],
      atSelectedTags: [],
    };
  },

  methods: {
    checkExperienceCheckbox(e) {
      if (e.path[0].checked) {
        this.atSelectedExperiencesChips.push({
          id: e.path[0]._value,
          title: e.path[0].attributes.placeholder.value,
        });
      } else {
        this.atSelectedExperiencesChips =
          this.atSelectedExperiencesChips.filter(
            (el) => el.id != e.path[0]._value
          );
      }
    },

    checkExperienceChip(e) {
      this.atSelectedExperiencesChips = this.atSelectedExperiencesChips.filter(
        (el) => el.id != e.path[0].attributes.placeholder.value
      );
      this.atSelectedExperiences = this.atSelectedExperiences.filter(
        (el) => el != e.path[0].attributes.placeholder.value
      );
    },

    atGoToStep2() {
      if (!this.atSelectedCategory) {
        return alert("You haven't selected any categories!");
      }

      let myData = {
        per_page: 100,
        filters: {
          category: { ids: this.atSelectedCategory },
        },
      };

      axios
        .get(config("BACKEND_ROOT") + "/admin/products", { params: myData })
        .then((response) => {
          this.atExperiences = response.data.data;
          this.atStep = 2;
        });
    },

    addTag() {
      axios
        .post(config("BACKEND_ROOT") + "/admin/product-tag", {
          product_ids: this.atSelectedExperiences,
          tag_ids: this.atSelectedTags,
        })
        .then((response) => {
          this.$emit("done");
          this.$modal.hide("add-tag-modal");
        });
    },
  },
};
</script>

<style scoped>
.abExperiencesList {
  max-height: 700px;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>