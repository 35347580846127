<template>
  <div>
    <slot :clicked="showTranslateModal">

    </slot>
    <modal name="translate-modal" height="auto">
      <div class="p-4">
        <div class="h3 mb-3">Add new translate </div>
        <form action="">
          <div v-if="items && !model_id" class="form-group col-lg-12">
            <label>Category</label>
            <select class="form-control" v-model="translate.model_id">
              <option disabled value="">Which one you want add translate</option>
              <option
                  v-for="item in items"
                  :value="item.id"
              >{{ item.title }}</option>
            </select>
          </div>
          <div class="form-group col-lg-12">
            <label>Language</label>
            <select class="form-control" v-model="translate.language">
              <option disabled value="">Select language</option>
              <option
                  v-for="item in languages"
                  :value="item.code"
              >{{ item.title }}</option>
            </select>
          </div>
          <div class="col-lg-12"
          v-for="item in keys"
          >
            <label>{{item.key}}</label>
            <input
                v-model="translate.translatable[item.key]"
                :type="item.type"
                class="form-control"
                :placeholder="item.key"
            />
          </div>



          <ul style="padding-left: 20px; margin-top: 20px">
            <li
                v-for="error in translate.errors"
                v-bind:key="error"
                class="text-danger"
            >
              {{ error }}
            </li>
          </ul>

          <button class="btn btn-primary mt-3 w-100" @click="saveTranslate">
            Save
          </button>
        </form>
      </div>
    </modal>
  </div>

</template>
<script>
import {config} from "@/helpers";
import Vue from "vue";

export default {
  name: 'Translator',
  data(){
    return {
      languages: [],
      translate: {
        translatable: {},
        model: "Category",
        model_id: "",
        language: "",

      },
    }
  },
  props: {
    items: {
      type: Array,
      required: false
    },
    keys: {
      type: Array,
      required: true,
    },
    model_id: {
      type: Number,
      required: false,
    },
    model: {
      type: String,
      required: true
    }
  },
  mounted() {
    this.getLanguages()
    this.generateTranslatable()
  },
  methods: {
    getLanguages(){
      axios.get(config("BACKEND_ROOT") + "/admin/languages").then((response) => {
        this.languages = response.data.data;
      });
    },

    showTranslateModal(){
      this.$modal.show("translate-modal");
    },
    saveTranslate() {
      if(! this.items && this.model_id){
        this.translate.model_id = this.model_id;
      }
      event.preventDefault();
      axios
          .post(config("BACKEND_ROOT") + "/admin/translate", this.translate)
          .then((response) => {
            Vue.notify({
              group   : 'notify',
              type    : 'success',
              title   : 'Hooray',
              duration: 1000,
              text    : "Data Saved"
            });
            setTimeout(function () {
              location.reload();
            }, 2000)
          })
          .catch((error) => {
            Vue.notify({
              group   : 'notify',
              type    : 'error',
              title   : 'Error!',
              duration: 5000,
              text    : Object.values(
                  error.response.data.errors
              ).flat()
            });

          });
    },
    generateTranslatable(){
      this.keys.forEach(item => {
        this.$set(this.translate.translatable, item.key, "")
      })
      console.log(this.translate);
    }
  }
}
</script>
<style scoped>
.add-item {
  color: #666;
  border: 2px dashed #999;
  padding-top: 17px;
  transition: 0.3s !important;
}
.add-item:hover {
  color: #f42f4b;
  border-color: #f42f4b;
}
</style>