<template>
  <div class="w-100">
    <h1>Reviews</h1>

  <div class="mt-4">
      <label for="status">status:</label>
    <div class="row">
      <div class="col-lg-5 mb-3 mt-2">
        <select v-model="inputFilters.publication_status" id="publication_status" class="form-control">
          <option value="pending">Pending</option>
          <option value='internal'>Internal</option>
          <option value='external'>External</option>
          <option value='rejected'>Rejected</option>
        </select>
      </div>
      <div class="col-lg-2">
        <button class="btn btn-primary" @click="filter" style="margin-top: 8px">
          <i class="fas fa-search"></i>
        </button>
      </div>
    </div>
  </div>

    <div class="table-responsive">
      <table class="table mt-4">
        <thead class="thead-light">
          <tr>
            <th scope="col">ID
              <table-sort-arrow column="id" :current="sort.by" @change="sortChanged"></table-sort-arrow>
            </th>
            <th scope="col">Product ID</th>
            <th scope="col">Product Name</th>
            <th scope="col">Author</th>
            <th scope="col">Date</th>
            <th scope="col">Rate
              <table-sort-arrow column="rating" :current="sort.by" @change="sortChanged"></table-sort-arrow>
            </th>
            <th scope="col">Publication Status</th>
            <th scope="col">Action</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="review in items"
            v-bind:key="review.id"
            :class="review.status == 1 ? 'new-response' : ''"
          >
            <td>{{ review.id }}</td>
            <td>{{ review.product.type_id }}</td>
            <td>{{ review.product.title }}</td>
            <td>{{ review.author }}</td>
            <td>{{ review.created_at }}</td>
            <td>{{ review.rating }}</td>
            <td>{{ review.publication_status}}</td>
            <td>
              <button
                class="btn btn-sm btn-primary ml-2"
                title="Edit"
                @click="showEditModal(review)"
              >
                <i class="fa fa-edit"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <nav>
      <ul class="pagination">
        <li
          class="page-item"
          v-bind:class="{ disabled: page == 1 }"
          @click="previousPage()"
        >
          <a class="page-link" href="#" tabindex="-1">Previous</a>
        </li>
        <li
          class="page-item"
          v-bind:class="{ disabled: page == lastPage }"
          @click="nextPage()"
        >
          <a class="page-link" href="#">Next</a>
        </li>
      </ul>
    </nav>

    <modal name="update-modal" height="auto">
      <div class="p-4">
        <button @click="$modal.hide('update-modal')" class="modal-close-button">
          ❌
        </button>
        <div class="h3 mb-3">Edit a review</div>
        <form action="">
          <div class="row mt-4">
            <div class="col-7">
              <label for="edit-form__productid">Product ID:</label>
              <p>{{ updateForm.product_id }}</p>
            </div>

            <div class="col-4">
              <label for="edit-form__rating">Rate:</label>
              <p>{{ updateForm.rating }}</p>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-7">
              <label for="edit-form__producttitle">Product:</label>
              <p>{{ updateForm.product_title }}</p>
            </div>

            <div class="col-4">
              <label for="edit-form__username">Author:</label>
              <p>{{ updateForm.author }}</p>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-7">
              <label for="edit-form__date">Date:</label>
              <p>{{ formatDate(updateForm.created_at) }}</p>
            </div>

            <div class="col-4">
              <label for="edit-form__status">Publication Satus:</label>
              <p>{{ updateForm.publication_status }}</p>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12">
              <label for="edit-form__text">Text:</label>
              <div class="text-container"><p>{{ updateForm.text }}</p></div> 
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-12">
              <label for="edit-form__triptype">Action:</label>
              <select
                v-model="updateForm.publication_status"
                id="edit-form__triptype"
                class="form-control"
              >
                <option value='internal'>Internal Publication</option>
                <option value='external'>External Publication</option>
                <option value='rejected'>Reject</option>
              </select>
            </div>
          </div>

          <button
            type="submit"
            class="btn btn-primary mt-3 w-100"
            @click.prevent="update()"
          >
            Update
          </button>
        </form>
      </div>
    </modal>
  </div>
</template>

<script>
import TableSortArrow from '../components/TableSortArrow.vue';
import { config } from "../helpers";

export default {
  name: "Reviews",
  components: { 'table-sort-arrow': TableSortArrow },
  data() {
    return {
      url: "",
      items: [],
      inputFilters: {
        status: null,
      },
      isFiltered: false,
      filters: {},
      sort: {},
      page: 1,
      lastPage: 1,

      updateForm: {},
    };
  },

  mounted() {
    this.url = config("BACKEND_ROOT") + "/admin/reviews";
    this.loadData();
  },

  methods: {
    loadData() {
      let myData = { page: this.page };

      if (this.isFiltered) {
        let filters = this.filters;
        myData = { ...myData, filters };
      }

      if (this.sort) {
        myData = { ...myData, sort_by: this.sort.by, sort_order: this.sort.order };
      }

      axios.get(this.url, { params: myData }).then((response) => {
        this.items = response.data.data;
        this.page = response.data.meta.current_page;
        this.lastPage = response.data.meta.last_page;
      });
      console.log(this.items)
    },

    filter() {
      this.page = 1;
      this.isFiltered = true;
      let filters = {};

      if (this.inputFilters.publication_status) {
        filters.publication_status = this.inputFilters.publication_status;
      }

      this.filters = filters;

      this.loadData();
    },

    sortChanged: _.debounce(function (sort) {
        this.page = 1;
        this.sort = sort;
        this.loadData();
    }, 500),

    togglePublish(review) {
      if (!confirm("Do you want to publish this review?")) {
        return;
      }

      let newStatus = review.status == 1 ? 2 : 1;

      axios
        .put(`${this.url}/${review.id}`, { ...review, status: newStatus })
        .then((response) => {
          this.loadData();
        });
    },

    showEditModal(review) {
      this.updateForm = {
        id: review.id,
        product_id: review.product.type_id,
        product_title: review.product.title,
        author: review.author,
        created_at: review.created_at,
        text: review.text,
        rating: review.rating,
        trip_type: review.trip_type,
        status: review.status,
        publication_status: review.publication_status
      };

      this.$modal.show("update-modal");
    },

    update() {
      axios
        .put(`${this.url}/${this.updateForm.id}`, {
          publication_status: this.updateForm.publication_status
        })
        .then((response) => {
          this.loadData();
          this.$modal.hide("update-modal");
        })
        .catch((error) => {
          alert(Object.values(error.response.data.errors).flat()[0]);
        });
    },

    destroy(reviewId) {
      if (!confirm("Are you sure about deleting this review?")) {
        return;
      }

      axios.delete(`${this.url}/${reviewId}`).then((response) => {
        this.loadData();
      });
    },

    nextPage() {
      if (this.page == this.lastPage) {
        return;
      }

      this.page++;
      this.loadData();
    },

    previousPage() {
      if (this.page == 1) {
        return;
      }

      this.page--;
      this.loadData();
    },

    formatDate(date) {
      if (!date) return null;

      date = new Date(date);

      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes()
      );
    },

    getStatus(status) {
      let classes = {
        1: "badge-warning",
        2: "badge-success",
      };

      return `<div class="badge ${classes[status]}">${
        status == 1 ? "Pending" : "Published"
      }</div>`;
    },
  },
};
</script>

<style scoped>
.table {
  font-size: 14px !important;
}

.new-response {
  background: #fff7d8;
}

.text-column {
  display: inline-block;
  width: 300px;
  white-space: pre-line !important;
}

.text-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #ccc; /* Add border */
  padding: 0 10px; /* Padding to give space inside the border */
}

.text-container p {
  margin: 0; /* Remove default margin */
  text-align: center; /* Center text */
  font-size: 16px; /* Adjust font size if needed */
}
</style>