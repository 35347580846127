<template>
  <div>
    <search-box
      @typing="searchCountry"
      @selected="selectCountry"
      :results="searchedCountries"
      :placeholder="this.name || 'Search country...'"
      class="w-100"
    ></search-box>
  </div>
</template>

<script>
import SearchBox from "../components/SearchBox.vue";
import { config } from "../helpers";

export default {
  components: { "search-box": SearchBox },

  props: ["name"],

  data() {
    return {
      countries: [],
      searchedCountries: [],
      selectedCountryName: "",
    };
  },

  mounted() {
    if (this.name) {
      this.selectedCountryName = this.name;
    }
  },

  methods: {
    searchCountry: _.debounce(function (q) {
      axios
        .get(`${config("BACKEND_ROOT")}/countries?q=${q}`)
        .then((response) => {
          this.countries = response.data.data;
          this.searchedCountries = this.countries.map((el) => el.name);
        });
    }, 400),

    selectCountry(countryName) {
      let country = this.countries.find((el) => el.name == countryName);

      window.country_id = country.id;

      this.$emit("selected", country.id);
    },
  },
};
</script>