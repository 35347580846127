import Vue from 'vue'
import App from './App.vue'
import { router } from './router'
import store from './store'
import axios from 'axios'
import Notifications from 'vue-notification'
import VueApexCharts from 'vue-apexcharts'

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(Notifications)
Vue.use(VueApexCharts)

import VueLodash from 'vue-lodash';
import lodash from 'lodash';
Vue.use(VueLodash, { name: 'custom', lodash: lodash });

import VuePersianDatetimePicker from 'vue-persian-datetime-picker';
Vue.component('date-picker', VuePersianDatetimePicker);

import DefaultLayout from './layouts/Default.vue'
import EmptyLayout from './layouts/EmptyLayout.vue'
Vue.component('default-layout', DefaultLayout)
Vue.component('empty-layout', EmptyLayout)
Vue.component('apexchart', VueApexCharts)

import VModal from 'vue-js-modal'
Vue.use(VModal)

import Loading from './components/Loading.vue'
Vue.component('loading', Loading)

import { config } from './helpers'

import VueCookies from 'vue-cookies'
Vue.use(VueCookies)
Vue.$cookies.config('30d')

window.axios = axios
axios.defaults.headers.common['Content-Type'] = 'application/json'
axios.defaults.headers.common['Accept'] = 'application/json'
axios.defaults.headers.common['Authorization'] = 'Bearer ' + $cookies.get('dd-access-token')

const interceptor = axios.interceptors.response.use(response => {
  return response;
}, error => {
  if (error.response.status === 401 && location.pathname !== '/login') {
    if ($cookies.get('dd-access-token')) {
      axios.interceptors.response.eject(interceptor);
      let refreshTokenId = $cookies.get('dd-refresh-token').split("|", 1);
      return axios.post(config('BACKEND_ROOT') + '/auth/refresh/' + refreshTokenId, {
        'refresh_token': $cookies.get('dd-refresh-token'),
      })
        .then(response => {
          $cookies.set('dd-access-token', response.data.access_token);
          $cookies.set('dd-refresh-token', response.data.refresh_token);
          error.response.config.headers['Authorization'] = axios.defaults.headers.common['Authorization'] = 'Bearer ' + response.data.access_token;
          return axios.request(error.config);
        })
        .catch(error => {
          $cookies.remove("dd-access-token");
          $cookies.remove("dd-refresh-token");
          location.href = '/login';
        });
    }
  }

  if (error.response.status === 403) {
    alert("You don't have access to this page!");
  }

  if (error.response.status === 500) {
    //
  }

  return Promise.reject(error);
});

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
