<template>
  <div class="w-100">
    <div class="data" v-if="loaded">
      <h1 class="d-inline">{{ order.booked_details.product.title }}</h1>

      <div class="mt-2">
        <span class="text-secondary">
          <i class="fa fa-ticket"></i>
          {{ order.booked_details.service_level.title }}
        </span>

        <span class="text-secondary ml-4">
          <i class="fa fa-folder-tree"></i>
          {{
            order.booked_details.service_level
              ? `${order.booked_details.service_level.pricing_type.replace(
                  "_",
                  " "
                )} / ${order.booked_details.service_level.availability_type.replaceAll(
                  "_",
                  " "
                )}`
              : "-"
          }}
        </span>

        <span class="text-secondary ml-4">
          <i class="fas fa-map-marker-alt"></i>
          {{ order.booked_details.starting_point.city.name }}
        </span>

        <span class="float-right" v-html="statusBadge"></span>
      </div>

      <hr />

      <div class="row pl-5 pr-5 mt-5 mb-4" style="font-size: 14px">
        <div class="col-lg-3">
          <div><strong>Order ID</strong><br />{{ order.id }}</div>
        </div>
        <div class="col-lg-3">
          <div>
            <strong>Cart ID</strong><br />
            <span
              class="link"
              @click="$router.push(`/carts/${cartID}`)"
              v-if="cartID != '-'"
              >{{ cartID }}</span
            >
            <span v-if="cartID == '-'">-</span>
          </div>
        </div>
        <div class="col-lg-3">
          <div>
            <strong>Gift ID</strong><br /><span
              class="link"
              @click="$router.push(`/gifts/${giftID}`)"
              v-if="giftID != '-'"
              >{{ giftID }}</span
            >
            <span v-if="giftID == '-'">-</span>
          </div>
        </div>
        <div class="col-lg-3">
          <div><strong>Status</strong><br />{{ order.status }}</div>
        </div>
      </div>

      <div class="row pl-5 pr-5 mt-5 mb-4" style="font-size: 14px">
        <div class="col">
          <div>
            <strong>Contact Person</strong><br />{{
              order.contact_person ? order.contact_person.name : "?"
            }}
          </div>
        </div>
        <div class="col">
          <div><strong>User Issuer</strong><br />{{ order.issuer.name }}</div>
        </div>
        <!-- <div
          class="col"
          v-if="['daily', 'nightly', 'per_time'].includes(availabilityType)"
        >
          <div>
            <strong>Time Frame</strong><br />{{
              availabilityType
                | timeFrameCalc(dailyNightlyDaysCount || perTimeScopToBookCount)
            }}
          </div>
        </div> -->
        <div class="col">
          <div>
            <strong>{{ orderIsPerPerson ? "Ordered" : "Items Ordered" }}</strong
            ><br />{{
              order
                | calcOrderCount(
                  orderIsPerPerson,
                  dailyNightlyDaysCount || perTimeScopToBookCount
                )
            }}
          </div>
        </div>
        <div class="col">
          <div>
            <strong>Passengers</strong><br />
            <span v-html="passengersStats"></span>
          </div>
        </div>
      </div>

      <div
        class="row pl-5 pr-5 mt-5 mb-4"
        v-if="availabilityType != 'pass'"
      >
        <div class="col-lg-2">
          <div><strong>Starts at</strong><br />{{ order.starts_at }}</div>
        </div>

        <div class="col-lg-1 text-secondary user-select-none">
          ــــــــــــــــ
        </div>

        <div class="col-lg-2">
          <div><strong>Ends at</strong><br />{{ order.ends_at }}</div>
        </div>
      </div>

      <hr />

      <div class="row pl-5 pr-5 text-center mt-4 mb-4">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-lg-3">
                  <strong>Leader name</strong><br />
                  {{ leader.first_name }}
                </div>

                <div class="col-lg-3">
                  <strong><i class="fa fa-phone mr-1 ml-4"></i> Phone number</strong><br />
                  <span v-if="leader.phone_number">{{ leader.phone_number }}</span>
                  <span v-if="! leader.phone_number">-</span>
                </div>

                <div class="col-lg-3">
                  <strong><i class="fa fa-envelope mr-1 mr-4"></i> Email address</strong><br />
                  <span v-if="leader.email">{{ leader.email }}</span>
                  <span v-if="! leader.email">-</span>
                </div>
                
                <div class="col-lg-3">
                  <span class="badge bg-primary text-light">Leader</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row pl-5 pr-5 text-center mt-3">
        <div class="pl-3 pr-3 card-group w-100">
          <div class="card pt-2 pb-2">
            <strong class="mb-1">Original price</strong>
            <div>
              <span class="h3">{{ order.total_prices.original.amount }}</span>
              {{ order.total_prices.original.currency.code }}
            </div>
          </div>

          <div class="card pt-2 pb-2">
            <strong class="mb-1">Agent price</strong>
            <div>
              <span class="h3">{{ order.total_prices.agent.amount }}</span>
              {{ order.total_prices.agent.currency.code }}
            </div>
          </div>

          <div class="card pt-2 pb-2 bg-success text-white">
            <strong class="mb-1">Sold price</strong>
            <div>
              <span class="h3">{{ order.total_prices.sold.amount }}</span>
              {{ order.total_prices.sold.currency.code }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-5 pt-5">
      <loading v-if="!loaded"></loading>
    </div>
  </div>
</template>

<script>
import { config } from "../helpers";
import moment from "moment";

export default {
  name: "Order",
  data() {
    return {
      id: this.$route.params.id,
      loaded: false,
      order: null,
    };
  },

  mounted() {
    this.url = config("BACKEND_ROOT") + "/admin/orders";
    this.loadData();
  },

  methods: {
    loadData() {
      axios.get(`${this.url}/${this.id}`).then((response) => {
        this.order = response.data;
        this.loaded = true;
      });
    },
  },

  computed: {
    statusBadge() {
      if (this.order.status == "Booked") {
        return `<div class="badge badge-success">Booked</div>`;
      } else if (this.order.status == "Canceled") {
        return `<div class="badge badge-danger">Canceled</div>`;
      }
    },

    dsInvoiceID() {
      let tag = this.order.tags.find((el) => el.key == "ds_invoice_id");

      if (tag) {
        return tag.value;
      }

      return "?";
    },

    availabilityType() {
      return (
        this.order.booked_details?.service_level?.availability_type ?? null
      );
    },

    orderIsPerPerson() {
      if (!this.order.booked_details) return null;
      return (
        this.order.booked_details.service_level.pricing_type == "per_person"
      );
    },

    dailyNightlyDaysCount() {
      return (
        (moment(this.order.starts_at, "YYYY-MM-DD").diff(
          moment(this.order.ends_at, "YYYY-MM-DD")
        ),
        "days") + 1
      );
    },

    passengers() {
      return String('<i class="fa fa-user ml-1"></i>').repeat(
        this.order.passengers
      );
    },

    passengersStats() {
      let priceCategories = {};

      for (const passenger of this.order.passengers) {
        const priceCategoryTitle = passenger?.price_category?.title;
        if (!priceCategoryTitle) return "-";
        if (priceCategories.hasOwnProperty(priceCategoryTitle)) {
          priceCategories[priceCategoryTitle]++;
        } else {
          priceCategories[priceCategoryTitle] = 1;
        }
      }

      let output = "";
      for (const priceCategory in priceCategories) {
        const content = `${priceCategory} x ${priceCategories[priceCategory]}`;
        output +=
          '<span class="badge badge-secondary ml-2">' + content + "</span>";
      }

      return output;
    },

    leader() {
      return this.order.passengers.find((el) => el.is_leader);
    },

    giftID() {
      for (let tag of this.order.tags) {
        if (tag.key.startsWith("dd_gift_")) {
          return tag.key.split("_")[2];
        }
      }

      return "-";
    },

    cartID() {
      for (let tag of this.order.tags) {
        if (tag.key == "dd_gift_id") {
          return tag.value;
        }
      }

      return "-";
    },
  },

  filters: {
    timeFrameCalc(orderAvailability, bookingCount) {
      switch (orderAvailability) {
        case "daily":
        case "nightly":
          return `${bookingCount} ${
            orderAvailability == "daily" ? "Day" : "Night"
          }${bookingCount > 1 ? "s" : ""}`;
        case "per_time":
          return `${bookingCount} Scope${bookingCount > 1 ? "s" : ""}`;
      }
      return "";
    },

    calcOrderCount(item, orderIsPerPerson, bookingCount) {
      if (orderIsPerPerson) return item.passengers.length;
      return item.bookings.length / bookingCount;
    },
  },
};
</script>

<style scoped>
.link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
</style>