<template>
  <div class="w-100">
    <div class="data" v-if="loaded">
      <h1 class="d-inline">Recommendation #{{ recommendation?.id }}</h1>
      <form action="" class="mt-4 mb-5">
        <div class="form-row">
          <div class="form-group col-lg-6">
            <label for="titleInput">Title</label>
            <input v-model="updateForm.title" class="form-control" id="titleInput" placeholder="Title" />
          </div>
        </div>
        <div class="form-row">
          <label for="translationsInput">Translations</label>
          <div v-for="(_, languageCode) in updateForm.translations" :key="languageCode" class="input-group">
            <div class="input-group-prepend">
              <span class="input-group-text">{{ languageCode }}</span>
            </div>
            <input v-model="updateForm.translations[languageCode]" class="form-control" id="translationsInput"
              placeholder="Translation" />

            <!-- Remove Svg Icon -->
            <svg
                @click="removeTranslationField(languageCode)"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                class="ml-2 cursor-pointer"
            >
              <path fill="none" d="M0 0h24v24H0z"/>
              <path
                  fill="#EC4899"
                  d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
              />
            </svg>
          </div>
        </div>
        <div class="form-row" v-if="languages.length">
          <select class="mx-1" v-model="selectedLanguage">
            <option v-for="language in languages" :key="language.code" :value="language.code">
              {{ language.name }}
            </option>
          </select>
          <button class="btn btn-primary mx-1" @click.prevent="addTranslation"><i class="fas fa-plus"></i> Add</button>
        </div>

        <div class="form-row mt-2">
          <div class="form-group col-lg-4">
            <label for="priorityInput">Priority</label>
            <input v-model="updateForm.priority" class="form-control" type="number" min="1" max="20"
              id="priorityInput" />
          </div>
        </div>

        <div class="form-group ">
          <label for="statusInput">Active</label>
          <input type="checkbox" v-model="updateForm.is_enabled" class="form-control" id="statusInput"
            style="width:auto" />
        </div>

        <div>
          <h5 class="d-inline">Products</h5>
          <draggable v-model="products" class="list-group product-list" :options="{ handle: '.handle' }">
            <div v-for="(product, index) in products" :key="product.id" class="list-group-item product-item handle">
              <i class="fas fa-arrows-alt" style="margin-left: .2rem;"></i>
              {{ product.id + ' - '+ product.title + ' - ' + product.location.city.name }}
            </div>
            <div slot="header">
              <v-select v-model="products" @search="fetchProducts" :options="options" label="title" multiple
                :filterable="false" placeholder="Select products">
                <template slot="no-options">
                  type to search products...
                </template>
                <template slot="option" slot-scope="option">
                  <div>{{ option.id + ' - '+ option.title + ' - ' + option.location.city.name }}</div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div style="">{{ option.id + ' - '+ option.title + ' - ' + option.location.city.name }}</div>
                </template>
              </v-select>
            </div>
          </draggable>
        </div>

        <ul style="padding-left: 20px;" class="mb-3" v-if="errors.length">
          <li v-for="error in errors" v-bind:key="error" class="text-danger">
            {{ error }}
          </li>
        </ul>
        <button type="submit" class="btn btn-primary mt-1 mr-2" @click="submit">Submit</button>
        <button class="btn btn-warning mt-1" @click="resetForm">Reset</button>
      </form>

    </div>

    <div class="mt-5 pt-5">
      <loading v-if="!loaded"></loading>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { config } from "../../helpers";
import VueSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import draggable from 'vuedraggable'


export default {
  name: "Recommendation",
  components: {
    'v-select': VueSelect,
    draggable,
  },
  data() {
    return {
      id: this.$route.params.id,
      loaded: false,
      recommendation: null,
      products: [],
      options: [],
      languages: [
        { code: "en", name: "English" },
        { code: "ar", name: "Arabic" },
        { code: "fr", name: "French" },
        {code: "es", name: "Spanish"},
      ],

      allLanguages: [
        { code: "en", name: "English" },
        { code: "ar", name: "Arabic" },
        { code: "fr", name: "French" },
        {code: "es", name: "Spanish"},
      ],
      selectedLanguage: 'ar',
      updateForm: {
        is_enabled: true,
        priority: 1,
        translations: {}
      },
      errors: [],
      debouncedFetchProducts : _.debounce((search, loading) => {
        loading(true);

        const params = {};
        params['filter[title]'] = search;
        params['available'] = 1;

        axios.get(config("BACKEND_ROOT") + "/admin/products", { params: params }).then((response) => {
          this.options = response.data.data;
          loading(false);
        });
      }, 350), // Set a delay of 350 milliseconds
    };
  },

  mounted() {
    this.url = config("BACKEND_ROOT") + "/admin/recommendations";
    this.loadData();
  },

  methods: {
    loadData() {
      if (this.id) {
        axios.get(`${this.url}/${this.id}`).then((response) => {
          this.recommendation = response.data.data;
          this.updateForm = JSON.parse(JSON.stringify(response.data.data));
          this.updateForm.translations = this.mapFromTitleTranslations(this.updateForm.translations);
          this.products = this.mapFromRecommendationProducts(this.recommendation.products);
        });
      }
      this.loaded = true;
    },

    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.fetchProducts(loading, search, this);
      }
    },

    fetchProducts(search, loading) {
      // Call the debounced function
      this.debouncedFetchProducts(search, loading);
    },

    addToProducts(option) {
      this.products.push(option);
    },


    submit(event) {
      event.preventDefault();

      const data = {
        title: this.updateForm.title,
        priority: this.updateForm.priority,
        is_enabled: this.updateForm.is_enabled,
        products: this.mapToRecommendationProducts(this.products),
      }

      const req = this.id ? axios.put(this.url + "/" + this.id, data) : axios.post(this.url, data);

      req.then((response) => {
        this.updateRecommendationTranslations(response.data.data.id)                  
      })          
      .then(() => {
            this.$router.push('/recommendations')
          });;
    },

    updateRecommendationTranslations(recommendationId) {
      if(Object.keys(this.updateForm.translations).length != 0) {
        return axios.post(
          config("BACKEND_ROOT") + "/admin/translate", 
          {
            "model": "recommendation",
            "model_id": recommendationId,
            "translatable": this.mapToTitleTranslations(this.updateForm.translations)
          }
        )
        .catch((error) => {
          this.errors = Object.values(error.response.data.errors).flat();
        });
      }
    },

    addTranslation() {
      if (this.selectedLanguage) {
        this.updateForm.translations[this.selectedLanguage] = "";
        this.languages = this.languages.filter((language) => language.code !== this.selectedLanguage);
      }
    },

    resetForm(event) {
      event.preventDefault();
      this.updateForm = { ...this.recommendation };
    },

    mapFromTitleTranslations(translations) {
      return translations.reduce((carry, translation) => {
        carry[translation.language_code] = translation.value;
        return carry;
      }, {});
    },

    mapToTitleTranslations(translations) {
      return Object.entries(translations)
        .filter(([_, value]) => value)
        .map(([code, value]) => ({ 
          'language': code, 
          key: 'title', 
          value 
        }));
    },

    mapFromRecommendationProducts(products) {
      return products
        .sort((a, b) => a.priority - b.priority)
        .map((product) => {
          return {
            id: product.id,
            title: product.title,
            location: product.location,
          }
        });
    },

    mapToRecommendationProducts(products) {
      return products.map((product, index) => {
        return {
          id: product.id,
          priority: index + 1,
        }
      });
    },

    removeTranslationField(index) {
      this.$delete(this.updateForm.translations,index);
      // Add the removed language back to the languages dropdown
      const language = this.allLanguages.find(lang => lang.code === index);
      if (language) {
        this.languages.push(language)
      }
    },

  },
};
</script>

<style>
.product-item {
  cursor: pointer;
  background-color: lightgrey !important;
  margin-top: 0.5rem;
}

.product-list {
  padding: .5rem;
  margin-bottom: .5rem;
}
</style>