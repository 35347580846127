<template>
  <div class="w-100">
    <div class="data" v-if="loaded">
      <div class="w-100">
        <!-- Your existing code -->
        <div v-if="successMessage" class="alert alert-success mt-3" role="alert">
          Changes have been submitted successfully!
        </div>
      </div>
      <form action="" class="mt-4 mb-5">
        <div class="form-row">
          <div class="form-group col-lg-6">
            <label for="description"><strong>About Us</strong></label>
            <textarea
                v-model="updateForm.about_us"
                class="form-control"
                id="description"
                placeholder="About Us"
            />
          </div>
        </div>

        <label for="about-us-translations"><strong>About us Translations</strong></label>
        <div class="form-row">
          <div v-for="(_, languageCode) in updateForm.translations.about_us" :key="languageCode" class="input-group col-lg-6 my-2">
            <div class="input-group-prepend">
              <span class="input-group-text">{{ languageCode }}</span>
            </div>
            <textarea v-model="updateForm.translations.about_us[languageCode]" class="form-control" id="about-us-translations"
                      placeholder="Translation"/>
            <!-- Remove Svg Icon -->
            <svg
                @click="removeTranslationField(languageCode, 'about_us')"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                class="ml-2 cursor-pointer"
            >
              <path fill="none" d="M0 0h24v24H0z"/>
              <path
                  fill="#EC4899"
                  d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
              />
            </svg>
          </div>
        </div>
        <div class="form-row mt-2" v-if="languages.about_us.length">
          <select class="mx-2 form-control col-lg-6" v-model="selectedLanguage">
            <option v-for="language in languages.about_us" :key="language.code" :value="language.code">
              {{ language.name }}
            </option>
          </select>
          <button class="btn btn-primary mx-1" @click.prevent="addTranslation('about_us')"><i class="fas fa-plus"></i> Add</button>
        </div>
        <hr>

        <div class="form-row my-2">
          <div class="form-group col-lg-6">
            <label class="text-gray-600 font-semibold text-lg"><strong>Company Highlights</strong></label>
            <vue-editor v-model="updateForm.company_highlights"></vue-editor>
          </div>
        </div>

        <label for="highlights-translations"><strong>Company Highlights Translations</strong></label>

        <div class="form-row">
          <div v-for="(_, languageCode) in updateForm.translations.company_highlights" :key="languageCode" class="input-group my-2">
            <div class="form-group col-lg-6">

              <div class="input-group-prepend">
                <span class="input-group-text">{{ languageCode }}</span>
              </div>

              <vue-editor v-model="updateForm.translations.company_highlights[languageCode]" id="highlights-translations"/>
            </div>
            <!-- Remove Svg Icon -->
            <svg
                @click="removeTranslationField(languageCode, 'company_highlights')"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                class="ml-2 cursor-pointer"
            >
              <path fill="none" d="M0 0h24v24H0z"/>
              <path
                  fill="#EC4899"
                  d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
              />
            </svg>
          </div>
        </div>
        <div class="form-row mt-2" v-if="languages.company_highlights.length">
          <select class="mx-2 form-control col-lg-6" v-model="selectedLanguage">
            <option v-for="language in languages.company_highlights" :key="language.code" :value="language.code">
              {{ language.name }}
            </option>
          </select>
          <button class="btn btn-primary mx-1" @click.prevent="addTranslation('company_highlights')"><i class="fas fa-plus"></i> Add</button>
        </div>

        <hr>

        <div class="form-row">
          <div class="form-group col-lg-6">
            <label for="short_description"><strong>Short Description</strong></label>
            <textarea

                v-model="updateForm.short_description"
                class="form-control"
                id="short_description"
                placeholder="short description"
            />
          </div>
        </div>
        <label for="short-desc-translations"><strong>Short Description Translations</strong></label>
        <div class="form-row">
          <div v-for="(_, languageCode) in updateForm.translations.short_description" :key="languageCode" class="input-group  col-lg-6 my-2">
            <div class="input-group-prepend">
              <span class="input-group-text">{{ languageCode }}</span>
            </div>
            <textarea v-model="updateForm.translations.short_description[languageCode]" class="form-control" id="short-desc-translations"
                      placeholder="Translation"/>
            <!-- Remove Svg Icon -->
            <svg
                @click="removeTranslationField(languageCode, 'short_description')"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                class="ml-2 cursor-pointer"
            >
              <path fill="none" d="M0 0h24v24H0z"/>
              <path
                  fill="#EC4899"
                  d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
              />
            </svg>
          </div>
        </div>
        <div class="form-row my-2" v-if="languages.short_description.length">
          <select class="mx-1 form-control col-lg-6" v-model="selectedLanguage">
            <option v-for="language in languages.short_description" :key="language.code" :value="language.code">
              {{ language.name }}
            </option>
          </select>
          <button class="btn btn-primary mx-1" @click.prevent="addTranslation('short_description')"><i class="fas fa-plus"></i> Add</button>
        </div>
        <hr>

        <div class="form-row">
          <div class="form-group col-lg-6">
            <label for="short_description"><strong>Terms And Conditions</strong></label>
            <vue-editor v-model="updateForm.terms_and_conditions"></vue-editor>
          </div>
        </div>
        <label for="terms-translations"><strong>Terms And Conditions Translations</strong></label>
        <div class="form-row">
          <div v-for="(_, languageCode) in updateForm.translations.terms_and_conditions" :key="languageCode" class="input-group  col-lg-6 my-2">
            <div class="input-group-prepend">
              <span class="input-group-text">{{ languageCode }}</span>
            </div>
            <textarea v-model="updateForm.translations.terms_and_conditions[languageCode]" class="form-control" id="terms-translations"
                      placeholder="Translation"/>
            <!-- Remove Svg Icon -->
            <svg
                @click="removeTranslationField(languageCode, 'terms_and_conditions')"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width="24"
                height="24"
                class="ml-2 cursor-pointer"
            >
              <path fill="none" d="M0 0h24v24H0z"/>
              <path
                  fill="#EC4899"
                  d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
              />
            </svg>
          </div>
        </div>
        <div class="form-row my-2" v-if="languages.terms_and_conditions.length">
          <select class="mx-1 form-control col-lg-6" v-model="selectedLanguage">
            <option v-for="language in languages.terms_and_conditions" :key="language.code" :value="language.code">
              {{ language.name }}
            </option>
          </select>
          <button class="btn btn-primary mx-1" @click.prevent="addTranslation('terms_and_conditions')"><i class="fas fa-plus"></i> Add</button>
        </div>
        <hr>

          <div class="form-row my-2">
              <div class="form-group col-lg-6">
                  <label for="description"><strong>Privacy Policy</strong></label>
                  <vue-editor v-model="updateForm.privacy_policy"></vue-editor>
              </div>
          </div>

          <label for="privacy-policy-translations"><strong>Privacy Policy Translations</strong></label>
          <div class="form-row">
              <div v-for="(_, languageCode) in updateForm.translations.privacy_policy" :key="languageCode" class="input-group col-lg-6 my-2">
                  <div class="input-group-prepend">
                      <span class="input-group-text">{{ languageCode }}</span>
                  </div>
                  <textarea v-model="updateForm.translations.privacy_policy[languageCode]" class="form-control" id="privacy-policy-translations"
                            placeholder="Translation"/>
                  <!-- Remove Svg Icon -->
                  <svg
                          @click="removeTranslationField(languageCode, 'privacy_policy')"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width="24"
                          height="24"
                          class="ml-2 cursor-pointer"
                  >
                      <path fill="none" d="M0 0h24v24H0z"/>
                      <path
                              fill="#EC4899"
                              d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                      />
                  </svg>
              </div>
          </div>
          <div class="form-row mt-2" v-if="languages.privacy_policy.length">
              <select class="mx-2 form-control col-lg-6" v-model="selectedLanguage">
                  <option v-for="language in languages.privacy_policy" :key="language.code" :value="language.code">
                      {{ language.name }}
                  </option>
              </select>
              <button class="btn btn-primary mx-1" @click.prevent="addTranslation('privacy_policy')"><i class="fas fa-plus"></i> Add</button>
          </div>

          <hr>

        <div class="form-row mt-2">
          <div class="form-group col-lg-2">
            <label for="fax"><strong>Fax</strong></label>
            <input
                v-model="updateForm.fax"
                class="form-control"
                id="fax"
                placeholder="fax"
            />
          </div>
          <div class="form-group col-lg-2">
            <label for="whatsappNumberInput"><strong>Whatsapp number</strong></label>
            <input
                v-model="updateForm.whatsapp_number"
                class="form-control"
                id="whatsappNumberInput"
                placeholder="Whatsapp number"
            />
          </div>
          <div class="form-group col-lg-4">
            <label class="text-gray-600 font-semibold text-lg"><strong>Telephones</strong></label>
            <template v-if="updateForm.telephones && updateForm.telephones.length > 0">
              <div
                  v-for="(_, index) in updateForm.telephones"
                  :key="`phoneInput-${index}`"
                  class="input wrapper flex items-center"
              >
                <input
                    type="text"
                    v-model="updateForm.telephones[index]"
                    class="h-10 rounded-lg outline-none p-2 form-control"
                    placeholder="Enter Phone Number"
                />
                <!-- Add Svg Icon -->
                <svg
                    @click="addField(index+1, 'telephones','')"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    class="ml-2 cursor-pointer"
                >
                  <path fill="none" d="M0 0h24v24H0z"/>
                  <path
                      fill="green"
                      d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                  />
                </svg>

                <!-- Remove Svg Icon -->
                <svg
                    v-show="updateForm.telephones.length > 1"
                    @click="removeField(index, updateForm.telephones)"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width="24"
                    height="24"
                    class="ml-2 cursor-pointer"
                >
                  <path fill="none" d="M0 0h24v24H0z"/>
                  <path
                      fill="#EC4899"
                      d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                  />
                </svg>
              </div>
            </template>
            <!-- Add button when telephones is null or empty -->
            <div v-else>
              <input
                  type="text"
                  v-model="updateForm.telephones[0]"
                  class="h-10 rounded-lg outline-none p-2 form-control"
                  placeholder="Enter Phone Number"
              />
              <!-- Add Svg Icon -->
              <svg
                  @click="addField(1, 'telephones','')"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  class="ml-2 cursor-pointer"
              >
                <path fill="none" d="M0 0h24v24H0z"/>
                <path
                    fill="green"
                    d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
                />
              </svg>
            </div>
          </div>
        </div>

        <div class="form-row mt-2">
          <div class="form-group col-lg-4">
            <label for="Email"><strong>Email</strong></label>
            <input
                v-model="updateForm.email"
                class="form-control"
                id="Email"
                placeholder="Email"
            />
          </div>
          <div class="form-group col-lg-4">
            <label for="bookingEmail"><strong>Booking Email</strong></label>
            <input
                v-model="updateForm.booking_email"
                class="form-control"
                id="bookingEmail"
                placeholder="Booking Email"
            />
          </div>
        </div>
        <hr>

        <label for="telegramNumberInput"><strong>Address</strong></label>
        <div class="form-row mt-2">
          <div class="form-group col-lg-4">
            <label for="address_description">Address description</label>
            <input
                v-model="updateForm.address.address_description"
                class="form-control"
                id="address_description"
                placeholder="Address description"
            />
          </div>
        </div>

        <div class="form-row mt-2">

          <div class="form-group col-lg-2">
            <label for="country">Country</label>

            <country-search-box @selected="(id) => id"></country-search-box>
          </div>

          <div class="form-group col-lg-2">
            <label for="city">City</label>

            <city-search-box @selected="(id) => updateForm.address.city_id = id"></city-search-box>
          </div>
          <div class="form-group col-lg-2">
            <label for="latitude">Latitude</label>
            <input
                v-model="updateForm.address.latitude"
                class="form-control"
                id="latitude"
                placeholder="latitude"
            />
          </div>
          <div class="form-group col-lg-2">
            <label for="longitude">Longitude</label>
            <input
                v-model="updateForm.address.longitude"
                class="form-control"
                id="longitude"
                placeholder="longitude"
            />
          </div>
        </div>
        <hr>

        <div class="form-group col-lg-4">
          <label for="Logo"><strong>Logo</strong></label>
          <file-upload :multiple="false" label="Select an image..." @done="file_name => updateForm.logo = file_name" class="mt-3"></file-upload>

        </div>

        <div class="form-group col-lg-4">
          <label for="images"><strong>Images</strong></label>
          <MultipleFilesUpload label="Select multiple images..." @done="file_name => updateForm.images = file_name" class="mt-3" :photos="photos"></MultipleFilesUpload>

        </div>
        <hr>

        <div class="form-group col-lg-4">
          <label class="text-gray-600 font-semibold text-lg"><strong>Social Medias</strong></label>

          <svg
              @click="addField(updateForm.social_medias.length, 'social_medias',{platform:'',url:''})"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              class="ml-2 cursor-pointer"
          >
            <path fill="none" d="M0 0h24v24H0z"/>
            <path
                fill="green"
                d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
            />
          </svg>

          <template v-if="updateForm.social_medias && updateForm.social_medias.length > 0">

            <div
                v-for="(input, index) in updateForm.social_medias"
                :key="`social_medias-${index}`"
                class="input wrapper flex items-center"
            >
              <select v-model="updateForm.social_medias[index].platform" class="form-control">
                <option v-for="platform in platforms" :key="platform" :value="platform">{{ platform }}</option>
              </select>
              <input type="text" v-model="updateForm.social_medias[index].url" placeholder="URL" class="form-control">
              <svg
                  v-show="updateForm.social_medias.length >= 1"
                  @click="removeField(index, updateForm.social_medias)"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  class="ml-2 cursor-pointer"
              >
                <path fill="none" d="M0 0h24v24H0z"/>
                <path
                    fill="#EC4899"
                    d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                />
              </svg>
            </div>

          </template>

        </div>

        <hr>

        <div class="form-group col-lg-4">
          <label class="text-gray-600 font-semibold text-lg"><strong>Achievements</strong></label>

          <svg
              @click="addField(updateForm.achievements.length, 'achievements',{title:'',photo:''})"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              class="ml-2 cursor-pointer"
          >
            <path fill="none" d="M0 0h24v24H0z"/>
            <path
                fill="green"
                d="M11 11V7h2v4h4v2h-4v4h-2v-4H7v-2h4zm1 11C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16z"
            />
          </svg>

          <template v-if="updateForm.achievements && updateForm.achievements.length > 0">
            <div
                v-for="(input, index) in updateForm.achievements"
                :key="`achievement-${index}`"
                class="input wrapper flex items-center my-2"
            >
              <label for="Logo">Title</label>
              <input type="text" v-model="updateForm.achievements[index].title" placeholder="Title" class="form-control">
              <file-upload :multiple="false" label="Select an image..." @done="file_name => updateForm.achievements[index].photo = file_name">updateForm.achievements[index].photo</file-upload>

              <svg
                  v-show="updateForm.achievements.length >= 1"
                  @click="removeField(index, updateForm.achievements)"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  class="ml-2 cursor-pointer"
              >
                <path fill="none" d="M0 0h24v24H0z"/>
                <path
                    fill="#EC4899"
                    d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm0-9.414l2.828-2.829 1.415 1.415L13.414 12l2.829 2.828-1.415 1.415L12 13.414l-2.828 2.829-1.415-1.415L10.586 12 7.757 9.172l1.415-1.415L12 10.586z"
                />
              </svg>
            </div>
          </template>
        </div>


        <ul style="padding-left: 20px;" class="mb-3" v-if="errors.length">
          <li v-for="error in errors" v-bind:key="error" class="text-danger">
            {{ error }}
          </li>
        </ul>


        <button type="submit" class="btn btn-primary mt-1 mr-2" @click="updateCompany">Update</button>
      </form>

    </div>

    <div class="mt-5 pt-5">
      <loading v-if="!loaded"></loading>
    </div>
  </div>
</template>

<script>
import {config} from "../helpers";
import CountrySearchBox from "../components/CountrySearchBox.vue";
import CitySearchBox from "../components/CitySearchBox.vue";
import FileUpload from "@/components/FileUpload";
import MultipleFilesUpload from "@/components/MultipleFilesUpload";
import {VueEditor} from "vue2-editor";

export default {
  name: "CompanyInfoEdit",
  components: {
    MultipleFilesUpload,
    FileUpload,
    "country-search-box": CountrySearchBox,
    "city-search-box": CitySearchBox,
    VueEditor

  },
  data() {
    return {
      id: this.$route.params.id,
      loaded: false,
      successMessage: false,
      company: null,
      platforms: [], // Array to store social media platforms fetched from API
      photos: [],
      updateForm: {
        translations: {},
        about_us: '',
        privacy_policy: '',
        short_description: '',
        terms_and_conditions: '',
        logo: '',
        images: [],
        telephones: [],
        company_highlights: '',
        achievements: [],
        address: {
          city_id: '',
          latitude: '',
          longitude: '',
          address_description: ''
        },
        social_medias: [],
        fax: '',
        whatsapp_number: '',
        email: '',
        booking_email:'',
      },
      allLanguages: [
        {code: "ar", name: "Arabic"},
        {code: "fr", name: "French"},
        {code: "es", name: "Spanish"},
      ],
      languages: {
        about_us: [
          {code: "ar", name: "Arabic"},
          {code: "fr", name: "French"},
          {code: "es", name: "Spanish"},
        ],
        privacy_policy: [
          {code: "ar", name: "Arabic"},
          {code: "fr", name: "French"},
          {code: "es", name: "Spanish"},
        ],
        short_description: [
          {code: "ar", name: "Arabic"},
          {code: "fr", name: "French"},
          {code: "es", name: "Spanish"},
        ],
        company_highlights: [
          {code: "ar", name: "Arabic"},
          {code: "fr", name: "French"},
          {code: "es", name: "Spanish"},
        ],
        terms_and_conditions: [
          {code: "ar", name: "Arabic"},
          {code: "fr", name: "French"},
          {code: "es", name: "Spanish"},
        ]


      },
      selectedLanguage: 'ar',
      errors: [],
    };
  },

  mounted() {
    this.url = config("BACKEND_ROOT") + "/admin/company-info";
    this.fetchPlatforms(); // Fetch social media platforms when component is mounted
    this.loadData();

  },

  methods: {
    async fetchPlatforms() {
      try {
        const response = await axios.get(config("BACKEND_ROOT") + '/admin/social-platforms'); // Adjust the URL as per your API endpoint
        this.platforms = response.data.data; // Update platforms array with data from API
      } catch (error) {
        console.error('Error fetching platforms:', error);
      }
    },

    loadData() {
      axios.get(`${this.url}`).then((response) => {
        this.company = response.data.data;
        this.id = this.company.id
        this.updateForm = JSON.parse(JSON.stringify({
          ...this.company,
          social_medias: this.company.social_medias,
          achievements: this.company.achievements.map((achievement) => {
            let fileName = achievement.photo ? "files" + achievement.photo.substring(achievement.photo.lastIndexOf('/')) : null;
            return {
              title: achievement.title,
              photo: fileName
            }
          }),
          address: this.company.address ? {city_id: this.company.address.city?.id, latitude: this.company.address.latitude, longitude: this.company.address.longitude, address_description: this.company.address.address_description} : [{
            city_id: '',
            latitude: '',
            longitude: '',
            address_description: ''
          }],
        }));
        this.updateForm.translations = this.mapFromCompanyInfoTranslations(this.updateForm.translations);
        this.photos = this.company.images.map(function (photo) {
          return {"url": photo};
        });
        this.loaded = true;
      }).catch((reason) => {
        console.log(reason)
        if (reason.response.status === 404) {
          this.loaded = true;
        }
      });
    },

    updateCompany(event) {
      event.preventDefault();
      let formData = {...this.updateForm}; // Clone the form data

      // Remove fields that have not been modified
      if (this.company) {
        if (formData.logo === this.company.logo) {
          delete formData.logo;
        }
        if (JSON.stringify(formData.images) === JSON.stringify(this.company.images)) {
          delete formData.images;
        }
        if ((JSON.stringify(formData.social_medias) === JSON.stringify(this.company.social_medias)) || (JSON.stringify(formData.social_medias) === JSON.stringify([{platform: '', url: ''}]))) {
          delete formData.social_medias;
        }
        if ((JSON.stringify(formData.achievements) === JSON.stringify(this.company.achievements)) || (JSON.stringify(formData.achievements) === JSON.stringify([{title: '', photo: ''}]))) {
          delete formData.achievements;
        }
        // Check for other fields like telephones, social media, etc., and remove if not modified
      }
      if (this.id) {
        axios
            .put(this.url + "/" + this.id, formData)
            .then((response) => {
              if (Object.keys(this.updateForm.translations).length > 0) {
                this.updateCompanyInfoTranslations(response.data.data.id)
              }else {
                this.successMessage = true;
                window.scrollTo({
                  top: 0,
                  behavior: "smooth", // Optional: smooth scrolling animation
                });
              }
            })
            .catch((error) => {
              this.errors = Object.values(error.response.data.errors).flat();
            });
      } else {
        axios
            .post(this.url, formData)
            .then((response) => {
              if (Object.keys(this.updateForm.translations).length  > 0) {
                this.updateCompanyInfoTranslations(response.data.data.id)
              }else {
                this.successMessage = true;
                window.scrollTo({
                  top: 0,
                  behavior: "smooth", // Optional: smooth scrolling animation
                });
              }
            })
            .catch((error) => {
              this.errors = Object.values(error.response.data.errors).flat();
            });
      }

    },
    updateCompanyInfoTranslations(companyId) {
      return axios.post(
          config("BACKEND_ROOT") + "/admin/translate",
          {
            "model": "company",
            "model_id": companyId,
            "translatable": this.mapToTranslations(this.updateForm.translations)
          }
      ).then((response) => {
        this.successMessage = true;
        window.scrollTo({
          top: 0,
          behavior: "smooth", // Optional: smooth scrolling animation
        });
      })
          .catch((error) => {
            this.errors = Object.values(error.response.data.errors).flat();
          });
    },
    mapFromCompanyInfoTranslations(translations) {
      return translations.reduce((carry, translation) => {
        if (!carry[translation.key]) {
          carry[translation.key] = {};
        }
        carry[translation.key][translation.language_code] = translation.value;
        return carry;
      }, {});
    },

    mapToTranslations(translations) {
      return Object.entries(translations)
          .flatMap(([key, values]) =>
              Object.entries(values).map(([language, value]) => ({
                'language': language,
                key,
                value,
              }))
          );
    },
    addTranslation(key) {
      if (this.selectedLanguage) {
        if (!this.updateForm.translations.hasOwnProperty(key)) {
          // If the key doesn't exist, create an empty object for it
          this.$set(this.updateForm.translations, key, {});
        }
        if (!this.updateForm.translations[key].hasOwnProperty(this.selectedLanguage)) {
          this.$set(this.updateForm.translations[key], this.selectedLanguage, '');
        }
        this.languages[key] = this.languages[key].filter((language) => language.code !== this.selectedLanguage);
      }
    },
    addField(index, fieldType, structure) {
      this.$set(this.updateForm[fieldType], index, structure)
    },
    removeField(index, fieldType) {
      fieldType.splice(index, 1);
    },

    removeTranslationField(index, key) {
      this.$delete(this.updateForm.translations[key], index);
      // Add the removed language back to the languages dropdown
      const language = this.allLanguages.find(lang => lang.code === index);
      if (language) {
        this.languages[key].push(language)
      }

    },
  },

};
</script>