import { render, staticRenderFns } from "./Recommendations.vue?vue&type=template&id=459c8034&scoped=true&"
import script from "./Recommendations.vue?vue&type=script&lang=js&"
export * from "./Recommendations.vue?vue&type=script&lang=js&"
import style0 from "./Recommendations.vue?vue&type=style&index=0&id=459c8034&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "459c8034",
  null
  
)

export default component.exports