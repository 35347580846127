<template>
  <div class="w-100">
    <h1 class="mb-4">Edit meta data</h1>

    <div class="form">
      <label for="">Type:</label>
      <div class="form-row">
        <div class="form-group col-lg-6">
          <input v-model="subjectType" disabled style="width: 100%">
        </div>
      </div>

      <div v-if="this.subjectType == 'products'">
        <label for="">Item:</label>
        <div class="form-row">
          <div class="form-group col-lg-6">
            <input v-model="subject" disabled style="width: 100%">
          </div>
        </div>
      </div>

      <div v-if="this.subjectType === 'home' || (this.subjectType === 'products' && this.subjectId != null)" class="mb-4">
        <button class="btn btn-primary" @click="generateMetaData">Generate metadata with AI</button>

        <div v-if="metaDataLoading">Generating metadata with chat gpt...</div>
      </div>

      <label for="">Meta Title:</label>
      <div class="form-row">
        <div class="form-group col-lg-6">
          <input
            v-model="form.title"
            class="form-control"
            placeholder="Title"
          />
        </div>
      </div>

      <label for="">Meta description:</label>
      <div class="form-row">
        <div class="form-group col-lg-6">
          <textarea
            v-model="form.description"
            class="form-control"
            placeholder="Description"
          />
        </div>
      </div>

      <ul style="padding-left: 20px; margin-top: 20px">
        <li v-for="error in errors" v-bind:key="error" class="text-danger">
          {{ error }}
        </li>
      </ul>

      <button
        type="submit"
        class="btn btn-success pl-4 pr-4"
        @click="submit()"
      >
        Update
      </button>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { config } from "../../helpers";
import metadataOptions from "@/static/metadata.json"

export default {
  name: "MetaDataEdit",
  data() {
    return {
      type: this.$route.params.type,
      id: this.$route.params.id,

      subjectTypes: metadataOptions,

      subject: null,
      selectedSubject: {},

      subjectType: null,
      subjectId: null,

      metaDataLoading: false,

      form: {
        title: null,
        description: null,
      },
      errors: [],
    };
  },

  mounted() {
    this.loadMetaData();
  },

  methods: {
    loadMetaData() {
      if (this.type === 'home'){
        this.type = 'products';
      }

      axios
        .get(`${config("BACKEND_ROOT")}/admin/meta-data/${this.type}/${this.id}`)
        .then(async response => {
          if (this.id == 0){
            this.subjectType = 'home';
          }else{
            this.subjectType = response.data.data.subject_type;
          }

          await this.loadSubjects();
          this.form.title = response.data.data.title;
          this.form.description = response.data.data.description;
          this.subjectId = response.data.data.subject_id;
        })
    },

    loadSubjects() {
      this.subjectTypes.forEach((subject) => {
        if(this.subjectType == subject.title){
          this.selectedSubject = subject;
        }
      });

      if(this.selectedSubject.value != 0){
        axios
            .get(`${config("BACKEND_ROOT")}/${this.selectedSubject.title}/${this.id}`)
            .then((response) => {
              console.log(response.data.data);
              this.subject = response.data.data.id + ' - '+ response.data.data.title;
            })
            .catch((err) => {
              axios
                  .get(`${config("BACKEND_ROOT")}/admin/${this.selectedSubject.title}/${this.id}`)
                  .then((response) => {
                    this.subject = response.data.data.id + ' - '+ response.data.data.title;
                  });
            });

      }
    },

    async generateMetaData() {
      this.metaDataLoading = true;

      var type = 'products';
      if (this.subjectType === 'home'){
        var id = 0;
      }else{
        id = this.subjectId;
      }

      axios.post(`${config("BACKEND_ROOT")}/admin/meta-data/${type}/${id}/ai`,).then((response) => {
        var res = response.data.data;
        this.form.title = res.title;
        this.form.description = res.description;
        this.metaDataLoading = false;
      }).catch((error) => {
        console.error('Error:', error);
      });
    },

    submit() {
      if(this.subjectId == 0){
        this.subjectType = 'products'
      }

      axios
        .post(`${config("BACKEND_ROOT")}/admin/meta-data/${this.subjectType}/${this.subjectId}`, this.form)
        .then(response => {
          this.$router.push("/meta-data");
        })
        .catch(error => {
          this.errors = Object.values(error.response.data.errors).flat();
        });
    },
  },
};
</script>
