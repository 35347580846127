<template>
  <div class="tsa d-inline ml-1">
    <i
      :class="[
        { 'fa-caret-down': order == 'desc', 'fa-caret-up': order == 'asc', 'gray-arrow': ! isCurrent, 'black-arrow': isCurrent },
        'fa',
      ]"
      @click="toggle()"
    ></i>
  </div>
</template>

<script>
export default {
  props: ["column", "current"],

  data() {
    return {
      order: "desc",
      isCurrent: false,
    };
  },

  methods: {
    toggle() {
      this.order = this.order == "desc" ? "asc" : "desc";
      this.$emit("change", { by: this.column, order: this.order });
    },
  },

  watch: {
    current: function (newVal) {
      this.isCurrent = this.column == this.current;
    },
  },
};
</script>

<style scoped>
.tsa {
  cursor: pointer !important;
}

.gray-arrow {
    opacity: .3;
}

.black-arrow {
    opacity: 1;
}
</style>