<template>
  <div class="w-100">
    <h1>Experiences Management</h1>

    <div class="row mt-4">
      <div class="col-lg-3">
        <label for="inputFiltersSearch">Search by name:</label><br />
        <input
          placeholder="Search..."
          v-model="inputFilters.search"
          class="form-control"
          id="inputFiltersSearch"
        />
      </div>
      <div class="col-lg-3">
        <label for="inputFiltersCategory">Category:</label><br />
        <select
          v-model="inputFilters.category"
          id="inputFiltersCategory"
          class="form-control"
        >
          <option
            v-for="category in categories"
            v-bind:key="category.id"
            :value="category.id"
          >
            {{ category.title }}
          </option>
        </select>
      </div>
      <div class="col-lg-2 pt-4">
        <button class="btn btn-primary" @click="filter" style="margin-top: 7px">
          <i class="fas fa-search"></i>
        </button>
      </div>
<!-- 
      <div class="col-lg-4 text-right pt-4">
        <button
          class="btn btn-success mr-2"
          style="margin-top: 7px"
          @click="showAddBlackoutDateModal"
        >
          Add blackout date
        </button>
        <button
          class="btn btn-success"
          style="margin-top: 7px"
          @click="showAddTagModal"
        >
          Add tag
        </button>
      </div> -->
    </div>

    <div class="table-responsive">
      <table class="table mt-4">
        <thead class="thead-light">
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Title</th>
            <th scope="col">Categories</th>
            <th scope="col">Tags</th>
            <!-- <th scope="col">Blackout Dates</th> -->
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="product in items" v-bind:key="product.id">
            <td>{{ product.id }}</td>
            <td>{{ product.title }}</td>
            <td :title="getCategories(product)">
              {{ short(getCategories(product), 50) }}
            </td>
            <td
              :class="getTags(product) == 'Add' ? 'tagsLink tagsLinkAdd' : 'tagsLink'"
              :title="getTags(product)"
              @click="showEditTagsModal($event, product.id)"
            >
              {{ short(getTags(product), 50) }}
            </td>
            <!-- <td
              :class="getBlackoutDates(product) == 'Add' ? 'tagsLink tagsLinkAdd' : 'tagsLink'"
              :title="getBlackoutDates(product)"
              @click="showEditBlackoutDatesModal($event, product)"
            >
              {{ short(getBlackoutDates(product), 50) }}
            </td> -->
            <td>
              <b-dropdown text="" class="m-md-2" boundary="viewport">
                <b-dropdown-item @click="showBuyForForm(product.id)"
                  >Buy it for...</b-dropdown-item
                >
                <b-dropdown-item @click="showEditMetaDataModal(product)"
                  >Edit meta data</b-dropdown-item
                >
              </b-dropdown>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <nav class="float-left">
      <ul class="pagination">
        <b-pagination v-model="page" :total-rows="itemCount" :per-page="perPage" last-number></b-pagination>
      </ul>
    </nav>

    <span class="float-right mt-2" style="color: #aaa">
      {{ this.itemCount + " items" }}
    </span>

    <modal name="add-tag-modal" width="75%" height="auto" :adaptive="true">
      <add-tag-modal
        :categories="categories"
        :tags="tags"
        v-on:done="loadItems()"
      ></add-tag-modal>
    </modal>

    <modal name="buy-for-modal" height="auto">
      <buy-for-modal :experienceId="buyForFormExperienceId"></buy-for-modal>
    </modal>

    <modal name="edit-tags-modal" height="auto">
      <edit-tags-modal
        :tags="tags"
        :etOldTags="etOldTags"
        :etSelectedTags="etSelectedTags"
        :etSelectedExperience="etSelectedExperience"
        v-on:done="loadItems()"
      ></edit-tags-modal>
    </modal>

    <modal
      name="add-blackout-date-modal"
      width="75%"
      height="auto"
      :adaptive="true"
    >
      <add-blackout-date-modal
        :blackoutDates="blackoutDates"
        :categories="categories"
        v-on:done="loadItems()"
      ></add-blackout-date-modal>
    </modal>

    <modal
      name="edit-blackout-dates-modal"
      width="75%"
      height="auto"
      :adaptive="true"
    >
      <edit-blackout-dates-modal
        :blackoutDates="blackoutDates"
        :productId="ebProductId"
        :blackoutDateIds="ebBlackoutDateIds"
        v-on:done="
          $modal.hide('edit-blackout-dates-modal');
          loadItems();
        "
      ></edit-blackout-dates-modal>
    </modal>

    <modal name="edit-meta-data-modal" height="auto">
      <edit-meta-data-modal :experience="editMetaDataExperience"></edit-meta-data-modal>
    </modal>
  </div>
</template>

<script>
import _ from "lodash";
import { config, short } from "../helpers";
import AddBlackoutDateModal from "../components/ViewComponents/Experiences/AddBlackoutDateModal.vue";
import EditBlackoutDatesModal from "../components/ViewComponents/Experiences/EditBlackoutDatesModal.vue";
import AddTagModal from "../components/ViewComponents/Experiences/AddTagModal.vue";
import BuyForModal from "../components/ViewComponents/Experiences/BuyForModal.vue";
import EditTagsModal from "../components/ViewComponents/Experiences/EditTagsModal.vue";
import EditMetaDataModal from '../components/ViewComponents/Experiences/EditMetaDataModal.vue';

export default {
  name: "Experiences",
  components: {
    "add-blackout-date-modal": AddBlackoutDateModal,
    "edit-blackout-dates-modal": EditBlackoutDatesModal,
    "add-tag-modal": AddTagModal,
    "buy-for-modal": BuyForModal,
    "edit-tags-modal": EditTagsModal,
    "edit-meta-data-modal": EditMetaDataModal,
  },
  data() {
    return {
      url: "",
      items: [],
      tags: [],
      categories: [],
      blackoutDates: [],

      inputFilters: {
        search: null,
      },
      isFiltered: false,
      filters: {},
      page: 1,
      lastPage: 1,
      perPage: 1,
      itemCount: null,

      buyForFormExperienceId: null,

      etSelectedExperience: null,
      etOldTags: [],
      etSelectedTags: [],

      ebProductId: null,
      ebBlackoutDateIds: [],

      editMetaDataExperience: null,
    };
  },

  mounted() {
    this.url = config("BACKEND_ROOT") + "/admin/products";
    this.loadItems();
    this.loadTags();
    this.loadCategories();
    this.loadBlackoutDates();
  },

  methods: {
    loadItems() {
      let myData = { page: this.page };

      if (this.isFiltered) {
        let filters = this.filters;
        myData = { ...myData, filters };
      }

      axios.get(this.url, { params: myData }).then((response) => {
        this.items = response.data.data;
        this.page = response.data.meta.current_page;
        this.lastPage = response.data.meta.last_page;
        this.itemCount = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
      });
    },

    filter() {
      this.page = 1;
      this.isFiltered = true;
      let filters = {};

      if (this.inputFilters.search) {
        filters.title = this.inputFilters.search;
      }

      if (this.inputFilters.category) {
        filters.category = { ids: this.inputFilters.category };
      }

      this.filters = filters;

      this.loadItems();
    },

    loadTags() {
      axios.get(config("BACKEND_ROOT") + "/admin/tags").then((response) => {
        this.tags = response.data.data;
      });
    },

    loadCategories() {
      axios.get(config("BACKEND_ROOT") + "/categories").then((response) => {
        this.categories = response.data.data;
      });
    },

    loadBlackoutDates() {
      axios
        .get(config("BACKEND_ROOT") + "/admin/blackoutdates")
        .then((response) => {
          this.blackoutDates = response.data.data;
        });
    },

    getCategories(product) {
      let categories = [];
      product.categories.forEach((el) => {
        categories.push(el.title);
      });

      return categories.length == 0 ? "-" : categories.join(", ");
    },

    getTags(product) {
      let tags = product.tags.filter((el) => {
        return el["key"].startsWith("dd_tag_");
      });

      let tagNames = [];
      for (let tag of tags) {
        for (let systemTag of this.tags) {
          if (tag["key"].split("_", 3)[2] == systemTag["id"]) {
            tagNames.push(systemTag["title"]);
          }
        }
      }

      if (tagNames.length == 0) {
        return "Add";
      }

      return tagNames.join(", ");
    },

    getBlackoutDates(product) {
      if (product.blackout_dates.length == 0) {
        return "Add";
      }

      return product.blackout_dates.map((obj) => obj.title).join(", ");
    },

    showAddTagModal() {
      this.$modal.show("add-tag-modal");
    },

    showBuyForForm(productId) {
      this.buyForFormExperienceId = productId;
      this.$modal.show("buy-for-modal");
    },

    showEditTagsModal(e, productId) {
      let tagTitles = e.target.attributes.title.nodeValue.split(", ");
      this.etOldTags = this.tags
        .filter((el) => tagTitles.includes(el.title))
        .map((o) => o["id"]);
      this.etSelectedTags = this.etOldTags;
      this.etSelectedExperience = productId;
      this.$modal.show("edit-tags-modal");
    },

    showAddBlackoutDateModal() {
      this.$modal.show("add-blackout-date-modal");
    },

    showEditBlackoutDatesModal(e, product) {
      this.ebProductId = product.id;
      this.ebBlackoutDateIds = product.blackout_dates.map((obj) => obj.id);
      this.$modal.show("edit-blackout-dates-modal");
    },

    showEditMetaDataModal(product) {
      this.editMetaDataExperience = product;
      this.$modal.show("edit-meta-data-modal");
    },

    short(string, limit) {
      return short(string, limit);
    },
  },

  watch: {
    page: function (val) {
      this.loadItems();
    },
  },
};
</script>

<style scoped>
.table {
  font-size: 14px !important;
}

.atExperiencesList {
  max-height: 700px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.bg-gray {
  background: #f7f5f5;
  padding: 3px 12px;
}

.tagsLink {
  color: blue;
}
.tagsLink:hover {
  text-decoration: underline;
  cursor: pointer;
}
.tagsLinkAdd {
  color: gray !important;
}
</style>