<template>
  <div>
    <b-form @submit="onSubmit" class="login-form">
      <div class="login-form__header text-center pt-4 pb-4">
        <!-- <i class="fa fa-key mt-2 mb-3"></i> -->
        <!-- <div class="login-form__logo"></div> -->
        <div>Login to Admin Panel</div>
      </div>

      <div class="login-form__body p-4">
        <b-form-group
          id="input-group-1"
          label="Email address:"
          label-for="input-1"
          description="We'll never share your email with anyone else."
        >
          <b-form-input
            id="input-1"
            name="email"
            v-model="form.email"
            type="email"
            placeholder="Enter email"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group id="input-group-2" label="Password:" label-for="input-2">
          <b-form-input
            id="input-2"
            v-model="form.password"
            type="password"
            placeholder="Password"
            required
          ></b-form-input>
        </b-form-group>

        <p class="text-danger" v-show="hasErrors">User not found!</p>

        <b-button type="submit" variant="danger">Login</b-button>
      </div>
    </b-form>
  </div>
</template>

<script>
import * as Helpers from "../../helpers";
import axios from "axios";

export default {
  data() {
    return {
      form: {
        email: "",
        password: "",
      },
      hasErrors: false,
    };
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      this.hasErrors = false;

      axios
        .post(Helpers.config("BACKEND_ROOT") + "/auth/login", this.form)
        .then((response) => {
          this.$cookies.set("dd-access-token", response.data.access_token);
          this.$cookies.set("dd-refresh-token", response.data.refresh_token);
          location.href = "/";
        })
        .catch((error) => {
          this.hasErrors = true;
        });
    },
  },
};
</script>

<style scoped>
.login-form {
  width: 400px;
  box-shadow: 0 0 25px rgb(0 0 0 / 10%);
  border-radius: 10px;
  margin: 60px auto 0;
  overflow: hidden;
}
.login-form__header {
  background: #f42f4b;
  font-family: "Inter";
  font-weight: bold;
  font-size: 20px;
  color: #fff;
}
.login-form__header i {
  font-size: 30px;
}
.login-form__logo {
  width: 200px;
  height: 50px;
  background: url("~@/assets/logo.svg") no-repeat;
  background-size: cover;
  margin: 5px auto 10px;
}
</style>