<template>
  <div class="w-100">
    <h1 class="mb-4">Create a Promotion code</h1>

    <div class="form">
      <div class="form-row">
        <div class="form-group col-lg-6">
          <label>Code</label>
          <input
            v-model="form.code"
            class="form-control"
            placeholder="promotion unique code"
          />
        </div>
        <div class="form-group col-lg-6">
          <label>Type</label>
          <select
              v-model="form.type"
              class="form-control"
          >
            <option
                v-for="type in types"
                :value="type"
                v-bind:key="type"
            >
              {{ type }}
            </option>
          </select>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-lg-6">
          <label>Value</label>
          <input
              v-model="form.value"
              v-bind:class="checkValue ? 'error-value' : ''"
              class="form-control"
              placeholder="promotion value"
          />
        </div>
        <div class="form-group col-lg-6">
          <label>Products</label>
          <multiselect
              v-model="selectedProducts"
              :options="products"
              :close-on-select="false"
              :clear-on-select="false"
              :hide-selected="false"
              :preserve-search="false"
              placeholder="Leave empty for all products"
              label="title"
              track-by="title"
              multiple="true"
              :preselect-first="true"
              id="example"
          >
          </multiselect>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-lg-6">
          <label for="birthdateInput">Start date</label>
          <date-picker
              v-model="form.start_at"
              type="datetime"
              value-type="YYYY-MM-DD HH:mm:ss"
              format="YYYY-MM-DD HH:mm:ss"
          ></date-picker>
        </div>
        <div class="form-group col-lg-6">
          <label for="birthdateInput">Expiration date</label>
          <date-picker
              v-model="form.expire_at"
              type="datetime"
              value-type="YYYY-MM-DD HH:mm:ss"
              format="YYYY-MM-DD HH:mm:ss"
          ></date-picker>
        </div>
      </div>

      <button
        type="submit"
        class="btn btn-success pl-4 pr-4 mt-3 mb-3"
        @click="submit()"
      >
        Create
      </button>
    </div>
  </div>
</template>

<script>
import { config } from "../helpers";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import multiselect from 'vue-multiselect'
export default {

  components: {
    DatePicker,
    multiselect
  },

  data() {
    return {
      types: ['percent', 'amount'],
      url: "",
      loaded: false,
      products: [],
      selectedProducts :[],

      form: {
        code: "",
        type: "",
        value: "",
        start_at: "",
        expire_at: "",
        products: []
      },
      errors: []
    };
  },
  mounted() {
    this.getProducts();
  },

  created: {
    checkValue(){
      return this.form.type === 'percent' && (this.form.value < 1 || this.form.value > 100) && this.form.value !== ''
    }
  },
  watch: {
    errors() {
      this.errors.map(error => {
        this.$notify({
          group: 'notify',
          type: 'warn',
          title: 'Error!',
          text: error
        });
      });

    }
  },
  methods: {

    submit() {
      if(this.selectedProducts.length > 0){
        this.selectedProducts.map((product) => {
          this.form.products.push(product.id);
        });
      }
      axios
          .post(`${config("BACKEND_ROOT")}/admin/promotions`, this.form)
          .then((response) => {
            this.$router.push("/promotions?success=true");
          })
          .catch((error) => {
            this.errors = Object.values(error.response.data.errors).flat();
          });
    },

    getProducts(){
      axios
          .get(`${config("BACKEND_ROOT")}/admin/products`)
          .then((response) => {
            this.products = response.data.data;
          })
          .catch((error) => {
            this.errors = Object.values(error.response.data.errors).flat();
          });
    }
  },
};
</script>
<style src="../../node_modules/vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
input.error-value{
  outline-style: revert !important;
  border: 2px solid red;
}
input.error-value:focus{
  outline-style: revert !important;
  border-color: red;
}
</style>