<template>
  <div class="p-4">
    <button
      @click="$modal.hide('edit-meta-data-modal')"
      class="modal-close-button"
    >
      ❌
    </button>
    <div class="h3 mb-3">Edit meta data</div>

    <div class="container" v-if="loaded">
      <div class="row">
        <div class="w-100 mb-1">Title:</div>
        <input
          v-model="form.title"
          :placeholder="experience.title"
          class="form-control w-100 mb-3"
        />
      </div>

      <div class="row">
        <div class="w-100 mb-1">Description:</div>
        <textarea
          v-model="form.description"
          :placeholder="experience.short_description"
          class="form-control w-100 mb-3"
        ></textarea>
      </div>

      <div class="row">
        <div class="w-100 mb-1">Keywords:</div>
        <array-box
          :array="form.keywords"
          @update="(arr) => (form.keywords = arr)"
          class="w-100 mb-3"
        ></array-box>
      </div>

      <div class="row">
        <div class="w-100 mb-1">Image alternative text:</div>
        <input v-model="form.image_alt" class="form-control w-100" />
      </div>

      <ul class="mb-2 mt-2" v-if="errors" style="padding-left: 20px">
        <li v-for="error in errors" v-bind:key="error" class="text-danger">
          {{ error }}
        </li>
      </ul>

      <div class="row" style="direction: rtl;">
        <button
          class="btn btn-primary float-right mt-3"
          @click="submit()"
          v-text="metaDataExist ? 'Edit' : 'Add'"
        >
        </button>

        <button
          v-if="metaDataExist"
          class="btn btn-danger float-right mt-3 mr-3"
          @click="clear()"
        >
          Clear
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { config } from "../../../helpers";
import ArrayBox from "../../ArrayBox.vue";

export default {
  components: { "array-box": ArrayBox },
  props: ["experience"],

  created() {
    this.loadData();
  },

  data() {
    return {
      loaded: false,

      metaDataExist: false,

      form: {
        title: null,
        description: null,
        keywords: [],
        image_alt: null,
      },

      errors: [],
    };
  },

  methods: {
    loadData() {
      axios
        .get(
          `${config("BACKEND_ROOT")}/admin/products/${
            this.experience.id
          }/meta-data`
        )
        .then((response) => {
          this.loaded = true;
          this.metaDataExist = true;
          this.form = response.data.data;
        })
        .catch((error) => {
          this.loaded = true;
        });
    },

    submit() {
      axios
        .put(
          `${config("BACKEND_ROOT")}/admin/products/${
            this.experience.id
          }/meta-data`,
          this.form
        )
        .then((response) => {
          this.$modal.hide("edit-meta-data-modal");
        })
        .catch((error) => {
          this.errors = Object.values(error.response.data.errors).flat();
        });
    },

    clear() {
      if (!confirm("Do you want to clear the meta data?")) {
        return;
      }

      axios
        .delete(
          `${config("BACKEND_ROOT")}/admin/products/${
            this.experience.id
          }/meta-data`,
          this.form
        )
        .then((response) => {
          alert("The product meta data cleared!");
          this.$modal.hide("edit-meta-data-modal");
        })
        .catch((error) => {
          if (error.response.status == 404) {
            this.$modal.hide("edit-meta-data-modal");
          }
        });
    },
  },
};
</script>
