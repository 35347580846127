<template>
  <div class="w-100">
    <div class="data" v-if="loaded">
      <div class="w-100">
        <div class="my-3 col-5">
          <h5 class="d-inline">Which cities you want to active on your website</h5>

          <div slot="header">
            <v-select v-model="cities" @search="fetchCities" @input="setCity" :options="options" label="name"
                      :filterable="false" placeholder="Select cities" multiple>
              <template slot="no-options">
                type to search cities...
              </template>
              <template slot="option" slot-scope="option">
                <div>{{ option.name }}</div>
              </template>
              <template slot="selected-option" slot-scope="option">
                <div style="">{{ option.name }}</div>
              </template>
            </v-select>
          </div>
        </div>
        <hr>

        <div class="my-3 col-5">
          <h5 class="d-inline">Your website top destinations</h5>

          <draggable v-model="topCities" class="list-group product-list" :options="{ handle: '.handle' }">
            <div v-for="(topCity, index) in topCities" :key="topCity.id" class="list-group-item product-item handle">
              <i class="fas fa-arrows-alt" style="margin-left: .2rem;"></i>
              {{ topCity.name }}
            </div>
            <div slot="header">
              <v-select v-model="topCities" @search="fetchCities" :options="topCityOptions" label="name" multiple
                        :filterable="false" placeholder="Select cities">
                <template slot="no-options">
                  type to search products...
                </template>
                <template slot="option" slot-scope="option">
                  <div>{{ option.name }}</div>
                </template>
                <template slot="selected-option" slot-scope="option">
                  <div style="">{{ option.name }}</div>
                </template>
              </v-select>
            </div>
          </draggable>
        </div>

        <!-- Your existing code -->
        <div v-if="successMessage" class="alert alert-success mt-3" role="alert">
          Changes have been submitted successfully!
        </div>
      </div>
      <form action="" class="mt-4 mb-5">

        <ul style="padding-left: 20px;" class="mb-3" v-if="errors.length">
          <li v-for="error in errors" v-bind:key="error" class="text-danger">
            {{ error }}
          </li>
        </ul>


        <button type="submit" class="btn btn-primary mt-1 mr-2" @click="update">Update</button>
      </form>

    </div>

    <div class="mt-5 pt-5">
      <loading v-if="!loaded"></loading>
    </div>
  </div>
</template>

<script>
import {config} from "../helpers";
import axios from "axios";
import VueSelect from "vue-select";
import draggable from "vuedraggable";

export default {
  name: "CityManagement",
  components: {
    'v-select': VueSelect,
    draggable,
  },
  data() {
    return {
      loaded: false,
      successMessage: false,
      company: null,
      cities: [],
      topCities: [],
      options: [{name: "All Cities"}],
      topCityOptions: [],
      errors: [],

      debouncedFetchCities: _.debounce((search, loading) => {
        loading(true);
        axios.get(config("BACKEND_ROOT") + "/admin/cities", {params: {q: search}}).then((response) => {
          this.options = response.data.data;
          this.topCityOptions = JSON.parse(JSON.stringify(this.options));
          this.options.push({name: "All Cities"})
          loading(false);
        });
      }, 350), // Set a delay of 350 milliseconds

    };
  },


  mounted() {
    this.cityUrl = config("BACKEND_ROOT") + "/admin/client-cities";
    this.topCityUrl = config("BACKEND_ROOT") + "/admin/top-cities";
    this.loadData();

  },

  methods: {

    loadData() {
      axios.get(`${this.cityUrl}`).then((response) => {
        this.cities = response.data.data;
        if (this.cities.length ===0){
          this.cities=[{name: "All Cities"}]
        }
      })
      axios.get(`${this.topCityUrl}`).then((response) => {
        this.topCities = this.mapFromTopCities(response.data.data);
      })
      this.loaded = true;
    },

    fetchCities(search, loading) {
      // Call the debounced function
      this.debouncedFetchCities(search, loading);
    },

    update(event) {
      event.preventDefault();
      this.updateCities();
      this.updateTopCities()
    },

    updateCities() {

      let data;
      // If "All Cities" option is selected, set city_ids to an empty array
      if (this.cities.some(city => city.name === "All Cities")) {
        data = {
          city_ids: []
        };
      } else {
        data = {
          city_ids: this.cities.map(city => city.id)
        };
      }
      axios
          .put(this.cityUrl, data)
          .then(() => {
            this.successMessage = true;
          })
          .catch((error) => {
            this.errors = Object.values(error.response.data.errors).flat();
          });
    },

    updateTopCities() {
      const data = {
        cities: this.mapToTopCities(this.topCities),
      }
      axios
          .put(this.topCityUrl, data)
          .then(() => {
            this.successMessage = true;
          })
          .catch((error) => {
            this.errors = Object.values(error.response.data.errors).flat();
          });
    },
    mapFromTopCities(cities) {
      return cities
          .sort((a, b) => a.priority - b.priority)
          .map((city) => {
            return {
              id: city.city?.id,
              name: city.city?.name,
            }
          });
    },

    mapToTopCities(cities) {
      return cities.map((city, index) => {
        return {
          id: city.id,
          priority: index + 1,
        }
      });
    },

    setCity(list) {
      const foundIndex = list.findIndex((item) => {
        return item.name === "All Cities"
      })
      if (foundIndex !== -1) {
        this.cities = [{name: "All Cities"}]
      }
    }

  },

};
</script>