<template>
  <div class="w-100">
    <span class="font-weight-bold float-right pt-1" v-if="loaded"
      >{{ wallet.balance }} AED</span
    >
    <h1>Credit History of the user #{{ this.id }}</h1>

    <div class="table-responsive" v-if="this.loaded">
      <table class="table mt-4">
        <thead class="thead-light">
          <tr>
            <th scope="col">#</th>
            <th scope="col">Type</th>
            <th scope="col">Amount</th>
            <th scope="col">Comment</th>
            <th scope="col">Created at</th>
          </tr>
        </thead>
        <tbody v-for="(item, index) in wallet.transactions" v-bind:key="index">
          <td>{{ index + 1 }}</td>
          <td v-html="getTypeHTML(item.type)"></td>
          <td>{{ item.amount }}</td>
          <td>{{ item.meta.comment }}</td>
          <td>{{ formatDate(item.created_at) }}</td>
        </tbody>
      </table>
    </div>

    <div class="mt-5 pt-5">
      <loading v-if="!loaded"></loading>
    </div>
  </div>
</template>

<script>
import { config } from "../helpers";

export default {
  name: "UserPurchases",
  data() {
    return {
      url: "",
      id: this.$route.params.id,
      wallet: {},
      loaded: false,
    };
  },

  mounted() {
    this.url = config("BACKEND_ROOT") + "/admin/users";
    this.loadData();
  },

  methods: {
    loadData() {
      axios.get(`${this.url}/${this.id}/wallet-history`).then((response) => {
        this.wallet = response.data.data;
        this.loaded = true;
      });
    },

    formatDate(date) {
      if (!date) return null;

      date = new Date(date);

      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes()
      );
    },

    getTypeHTML(type) {
      if (type == "deposit") {
        return '<span class="badge badge-success">+</span>';
      } else {
        return '<span class="badge badge-danger">-</span>';
      }
    },
  },
};
</script>