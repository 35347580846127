<template>
  <div class="w-100">
    <h1>Promotion codes List</h1>

    <div class="row">
<!--      <div class="col-lg-3 mb-3" style="margin-top: 1px;">-->
<!--        <label>By code, or customer's name or id:</label>-->
<!--        <input-->
<!--            placeholder="Search..."-->
<!--            v-model="inputFilters.q"-->
<!--            class="form-control"-->
<!--            v-on:keyup.enter="filter"-->
<!--        />-->
<!--      </div>-->
<!--      <div class="col-lg-3">-->
<!--        <label for="date-datepicker">Filter by date:</label>-->
<!--        <date-picker-->
<!--            v-model="inputFilters.date"-->
<!--            id="date-datepicker"-->
<!--            locale="en"-->
<!--            format="YYYY-MM-DD"-->
<!--            range-->
<!--            clearable-->
<!--            color="#f42f4b"-->
<!--        />-->
<!--      </div>-->
<!--      <div class="col-lg-2">-->
<!--        <label class="d-block" style="visibility: hidden">Filter</label>-->
<!--        <button class="btn btn-primary" @click="filter">-->
<!--          <i class="fas fa-search"></i>-->
<!--        </button>-->

<!--        <button-->
<!--            class="btn btn-secondary ml-2"-->
<!--            @click="exportToCSV"-->
<!--            v-if="items"-->
<!--            title="Export to csv"-->
<!--        >-->
<!--          <i class="fas fa-cloud-download-alt"></i>-->
<!--        </button>-->
<!--      </div>-->

      <div class="col-lg-12">
        <button class="btn btn-success float-right" @click="$router.push({name: 'promotion.create'})">Create a New Promotion code</button>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table mt-2">
        <thead class="thead-light">
        <tr>
          <th scope="col">Code</th>
          <th scope="col">Type</th>
          <th scope="col">Value</th>
          <th scope="col">Start Date</th>
          <th scope="col">Expiration Date</th>
          <th scope="col">Start At</th>
          <th scope="col">Expire At</th>
          <th scope="col">Status</th>
        </tr>
        </thead>
        <tbody>
        <router-link
            tag="tr"
            :to="{ name: 'promotions.show', params: { id: item.id } }"
            v-for="item in items"
            style="cursor: pointer"
        >
          <td>{{  item.code }}</td>
          <td>{{  item.type }}</td>
          <td>{{  item.type === 'percent' ? (item.value * 100)+ '%' : item.value + ' AED' }}</td>
          <td>{{ moment(item.start_at).format('YYYY-MM-DD HH:mm:ss') }}</td>
          <td>{{ moment(item.expire_at).format('YYYY-MM-DD HH:mm:ss')}}</td>
          <td>{{moment(item.start_at).lang('en').fromNow()}}
          </td>
          <td>{{ moment(item.expire_at).lang('en').fromNow()}}</td>
          <td><b-alert v-if="moment(item.start_at).diff(moment()) > 0" variant="info" show>Not Started</b-alert>
            <b-alert v-else-if="moment(item.expire_at).diff(moment()) > 0" variant="success" show>Active</b-alert>
            <b-alert v-else variant="warning" show>Expired</b-alert>
            </td>
        </router-link>
        </tbody>
      </table>
    </div>

    <nav>
      <ul class="pagination">
        <b-pagination v-model="page" :total-rows="totalItems" :per-page="perPage" last-number></b-pagination>
      </ul>
    </nav>
  </div>
</template>

<script>
import { config } from "../helpers";
import moment from "moment";

export default {
  computed: {
    moment() {
      return moment
    }
  },
  data() {
    return {
      url: "",
      items: [],
      inputFilters: {
        q: "",
        date: [],
      },
      isFiltered: false,
      filters: {},
      page: 1,
      lastPage: 1,
      totalItems: 1,
      perPage: 1,
      success: this.$route.query.success ?? false,
    };
  },

  mounted() {
    this.url = config("BACKEND_ROOT") + "/admin/promotions";
    this.loadData();
    if(this.success){
      this.$notify({
        group: 'notify',
        type: 'success',
        title: 'Hooray',
        text: "New promotion code created successfully"
      });
    }
  },

  methods: {
    loadData() {
      let myData = { page: this.page };

      if (this.isFiltered) {
        myData = { ...myData, date: this.filters.date, q: this.filters.q };
      }

      axios.get(this.url, { params: myData }).then((response) => {
        this.items = response.data.data;
        this.page = response.data.meta.current_page;
        this.lastPage = response.data.meta.last_page;
        this.totalItems = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
      });
    },

    filter() {
      this.page = 1;
      this.isFiltered = true;
      let filters = {};

      if (this.inputFilters.date[0]) {
        filters.date = {};
        filters.date.start = this.inputFilters.date[0];
        filters.date.end = this.inputFilters.date[1];
      }

      if (this.inputFilters.q) {
        filters.q = this.inputFilters.q;
      }

      this.filters = filters;

      this.loadData();
    },
    formatDate(date) {
      if (!date) return null;

      date = new Date(date);

      return (
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate() +
          " " +
          date.getHours() +
          ":" +
          date.getMinutes()
      );
    },
  },


  watch: {
    page: function (val) {
      this.loadData();
    },
  },
};
</script>

<style scoped>
.table {
  font-size: 14px !important;
}
</style>