<template>
  <div class="w-100">
    <div class="data" v-if="loaded">
      <h1 class="d-inline">User #{{ user.id }}</h1>

      <form action="" class="mt-4 mb-5">
        <div class="form-row">
          <div class="form-group col-lg-6">
            <label for="firstnameInput">Firstname</label>
            <input
              v-model="updateForm.first_name"
              class="form-control"
              id="firstnameInput"
              placeholder="Firstname"
            />
          </div>
          <div class="form-group col-lg-6">
            <label for="lastnameInput">Lastname</label>
            <input
              v-model="updateForm.last_name"
              class="form-control"
              id="lastnameInput"
              placeholder="Lastname"
            />
          </div>
        </div>

        <div class="form-row mt-2">
          <div class="form-group col-lg-4">
            <label for="phoneNumberInput">Phone number</label>
            <input
              v-model="updateForm.phone_number"
              class="form-control"
              id="phoneNumberInput"
              placeholder="Phone number"
            />
          </div>
          <div class="form-group col-lg-4">
            <label for="whatsappNumberInput">Whatsapp number</label>
            <input
              v-model="updateForm.whatsapp_number"
              class="form-control"
              id="whatsappNumberInput"
              placeholder="Whatsapp number"
            />
          </div>
          <div class="form-group col-lg-4">
            <label for="telegramNumberInput">Telegram number</label>
            <input
              v-model="updateForm.telegram_number"
              class="form-control"
              id="telegramNumberInput"
              placeholder="Telegram number"
            />
          </div>
        </div>

        <div class="form-row mt-2">
          <div class="form-group col-lg-4">
            <label for="telegramNumberInput">Email</label>
            <input
                v-model="updateForm.email"
                class="form-control"
                id="telegramNumberInput"
                placeholder="Email"
            />
          </div>
          <div class="form-group col-lg-4">
            <label for="genderSelect">Gender</label>
            <select
              v-model="updateForm.gender"
              id="genderSelect"
              class="form-control"
            >
              <option value="1">Man</option>
              <option value="2">Woman</option>
              <option value="3">Unknown</option>
            </select>
          </div>
          <div class="form-group col-lg-4">
            <label for="birthdateInput">Birthday</label>
            <input
              v-model="updateForm.birth_date"
              type="date"
              class="form-control"
              id="birthdateInput"
              placeholder="yyyy-mm-dd"
            />
          </div>
        </div>

        <ul style="padding-left: 20px;" class="mb-3" v-if="errors.length">
          <li v-for="error in errors" v-bind:key="error" class="text-danger">
            {{ error }}
          </li>
        </ul>

        <button type="submit" class="btn btn-primary mt-1 mr-2" @click="updateUser">Edit</button>
        <button class="btn btn-warning mt-1" @click="resetForm">Reset</button>
      </form>

      <!-- <h5 class="float-left" v-if="addressesLoaded">Addresses</h5>
      <button class="btn btn-sm btn-success float-right" @click="$modal.show('create-address-modal')">New address</button>
      <div class="table-responsive">
        <table class="table mt-2">
          <thead class="thead-light">
            <tr>
              <th scope="col">Country</th>
              <th scope="col">City</th>
              <th scope="col">Address</th>
              <th scope="col">Postcode</th>
              <th scope="col">Default</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="address in addresses" v-bind:key="address.id">
              <td>{{ address.country.name }}</td>
              <td>{{ address.city.name }}</td>
              <td>{{ address.address }}</td>
              <td>{{ address.post_code }}</td>
              <td>{{ address.is_default ? "Yes" : "No" }}</td>
              <td>
                <button class="btn btn-sm btn-primary mr-2" @click="showEditAddressModal(address)"><i class="fa fa-pen"></i></button>
                <button class="btn btn-sm btn-danger" @click="deleteAddress(address.id)"><i class="fa fa-trash"></i></button>
              </td>
            </tr>
          </tbody>
        </table>
      </div> -->
    </div>

    <modal name="create-address-modal" height="auto">
      <div class="p-4">
        <button @click="$modal.hide('create-address-modal')" class="modal-close-button">❌</button>
        <div class="h3 mb-3">New address</div>
        <form action="">
          <div class="form-row mt-3">
            <div class="form-group col-lg-6">
              <country-search-box @selected="(id) => createAddressForm.country_id = id"></country-search-box>
            </div>
            <div class="form-group col-lg-6">
              <city-search-box @selected="(id) => createAddressForm.city_id = id"></city-search-box>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-lg-6">
              <label for="addressInput">Address</label>
              <input
                v-model="createAddressForm.address"
                class="form-control"
                id="addressInput"
                required
              />
            </div>
            <div class="form-group col-lg-3">
              <label for="postcodeInput">Postcode</label>
              <input
                v-model="createAddressForm.post_code"
                class="form-control"
                id="postcodeInput"
                required
              />
            </div>
            <div class="form-group col-lg-3">
              <label for="isDefaultInput">Is default?</label>
              <select v-model="createAddressForm.is_default" id="isDefaultInput" class="form-control" required>
                <option value="1">Yes</option>
                <option value="0">No</option>
              </select>
            </div>
          </div>

          <ul style="padding-left: 20px; margin-top: 20px">
            <li
              v-for="error in createAddressForm.errors"
              v-bind:key="error"
              class="text-danger"
            >
              {{ error }}
            </li>
          </ul>

          <button
            class="btn btn-primary mt-3 w-100"
            @click.prevent="createAddress"
          >
            Create
          </button>
        </form>
      </div>
    </modal>

    <modal name="edit-address-modal" height="auto">
      <div class="p-4">
        <button @click="$modal.hide('edit-address-modal')" class="modal-close-button">❌</button>
        <div class="h3 mb-3">Edit address</div>
        <form action="">
          <div class="form-row mt-3">
            <div class="form-group col-lg-6">
              <country-search-box :name="editAddressForm.country ? editAddressForm.country.name : '-'" @selected="(id) => editAddressForm.country_id = id"></country-search-box>
            </div>
            <div class="form-group col-lg-6">
              <city-search-box :name="editAddressForm.city ? editAddressForm.city.name : '-'" @selected="(id) => editAddressForm.city_id = id"></city-search-box>
            </div>
          </div>

          <div class="form-row">
            <div class="form-group col-lg-6">
              <label for="addressInputE">Address</label>
              <input
                v-model="editAddressForm.address"
                class="form-control"
                id="addressInputE"
                required
              />
            </div>
            <div class="form-group col-lg-3">
              <label for="postcodeInputE">Postcode</label>
              <input
                v-model="editAddressForm.post_code"
                class="form-control"
                id="postcodeInputE"
                required
              />
            </div>
            <div class="form-group col-lg-3 pt-4">
              <input type="checkbox" v-model="editAddressForm.is_default" id="isDefaultInputE" class="mr-1 mt-3">
              <label for="isDefaultInputE">Default</label>
            </div>
          </div>

          <ul style="padding-left: 20px; margin-top: 20px">
            <li
              v-for="error in editAddressForm.errors"
              v-bind:key="error"
              class="text-danger"
            >
              {{ error }}
            </li>
          </ul>

          <button
            class="btn btn-primary mt-3 w-100"
            @click.prevent="editAddress"
          >
            Edit
          </button>
        </form>
      </div>
    </modal>

    <div class="mt-5 pt-5">
      <loading v-if="!loaded"></loading>
    </div>
  </div>
</template>

<script>
import { config } from "../../helpers";
import CountrySearchBox from "../../components/CountrySearchBox.vue";
import CitySearchBox from "../../components/CitySearchBox.vue";

export default {
  name: "User",
  components: {
    "country-search-box": CountrySearchBox,
    "city-search-box": CitySearchBox,
  },
  data() {
    return {
      id: this.$route.params.id,
      loaded: false,
      user: null,
      updateForm: {},
      errors: [],

      addressesLoaded: false,
      addresses: [],
      createAddressForm: {},
      editAddressForm: {},
    };
  },

  mounted() {
    this.url = config("BACKEND_ROOT") + "/admin/users";
    this.loadData();
    this.loadAddresses();
  },

  methods: {
    loadData() {
      axios.get(`${this.url}/${this.id}`).then((response) => {
        this.user = response.data.data;
        this.updateForm = JSON.parse(JSON.stringify(response.data.data));
        this.loaded = true;
      });
    },

    loadAddresses() {
      axios.get(`${this.url}/${this.id}/addresses`).then((response) => {
        this.addresses = response.data.data;
        this.addressesLoaded = true;
      });
    },

    updateUser(event) {
      event.preventDefault();
      axios
        .put(this.url + "/" + this.updateForm.id, this.updateForm)
        .then((response) => {
          location.reload();
        })
        .catch((error) => {
          this.errors = Object.values(error.response.data.errors).flat();
        });
    },

    resetForm(event) {
      event.preventDefault();
      this.updateForm = { ...this.user };
    },

    deleteAddress(addressId) {
      if (confirm("Do you really want to delete this address?")) {
        axios
        .delete(`${this.url}/${this.id}/addresses/${addressId}`)
        .then((response) => {
          this.loadAddresses();
        });
      }
    },

    createAddress() {
      axios
        .post(`${this.url}/${this.id}/addresses`, this.createAddressForm)
        .then((response) => {
          this.createAddressForm = {};
          alert("Address created successfully!");
          this.$modal.hide("create-address-modal");
          this.loadAddresses();
        })
        .catch((error) => {
          this.createAddressForm.errors = Object.values(
            error.response.data.errors
          ).flat();
        });
    },

    showEditAddressModal(address) {
      this.editAddressForm = JSON.parse(JSON.stringify(address));
      this.$modal.show('edit-address-modal');
    },

    editAddress() {
      axios
        .put(`${this.url}/${this.id}/addresses/${this.editAddressForm.id}`, this.editAddressForm)
        .then((response) => {
          this.editAddressForm = {};
          alert("Address updated successfully!");
          this.$modal.hide("edit-address-modal");
          this.loadAddresses();
        })
        .catch((error) => {
          this.editAddressForm.errors = Object.values(
            error.response.data.errors
          ).flat();
        });
    },

    formatDate(date) {
      if (!date) return null;

      date = new Date(date);

      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes()
      );
    },
  },

  computed: {
    genderForHuman() {
      if (this.user.gender == 1) {
        return "Man";
      } else if (this.user.gender == 2) {
        return "Woman";
      }

      return "-";
    },
  },
};
</script>