<template>
    <div class="dashboard-loading"></div>
</template>

<script>
    export default {
        name: "Loading"
    }
</script>

<style>
    .dashboard-loading {
        display: block;
        width: 100px;
        height: 100px;
        vertical-align: text-bottom;
        border: 7px solid #999;
        border-right-color: transparent;
        border-radius: 50%;
        margin: 40px auto;
        -webkit-animation: spinner-border .75s linear infinite;
        animation: spinner-border .75s linear infinite;
    }
    @keyframes spinner-border {
        to { transform: rotate(360deg); }
    }
</style>