<template>
  <div class="p-4">
    <button @click="$modal.hide('buy-for-modal')" class="modal-close-button">
      ❌
    </button>
    <div class="h3 mb-3">Search for the {{ search.type }}</div>
    <div class="form-check d-inline mr-4">
      <input
        v-model="search.type"
        @change="searchUser"
        class="form-check-input"
        type="radio"
        id="searchUserOption"
        value="user"
        checked
      />
      <label class="form-check-label" for="searchUserOption"> Customer</label>
    </div>
    <div class="form-check d-inline">
      <input
        v-model="search.type"
        @change="searchUser"
        class="form-check-input"
        type="radio"
        id="searchCorporateOption"
        value="corporate"
      />
      <label class="form-check-label" for="searchCorporateOption">
        Corporate</label
      >
    </div>

    <input
      v-model="search.query"
      @keyup="searchUser"
      class="form-control mt-2"
      type="text"
      placeholder="Search..."
    />

    <div class="list-group mt-2">
      <label
        v-for="user in search.users"
        v-bind:key="user.id"
        class="list-group-item pl-5"
      >
        <input
          v-model="userId"
          class="form-check-input me-1"
          type="radio"
          :value="user.id"
          name="searchUserResultItem"
        />
        {{ user.email }} ({{ `${user.first_name} ${user.last_name}`.trim() }})
      </label>
    </div>

    <button
      class="btn btn-primary mt-2 float-right mt-3 mb-3"
      @click="loginAsUser()"
    >
      Login as the {{ search.type }}
    </button>
  </div>
</template>

<script>
import { config } from "../../../helpers";

export default {
  props: ["experienceId"],

  data() {
    return {
      userId: null,
      search: {
        type: "user",
        query: "",
        users: [],
      },
    };
  },

  methods: {
    searchUser: _.debounce(function () {
      let params = {
        per_page: 6,
        q: this.search.query,
        type: this.search.type,
      };

      axios
        .get(`${config("BACKEND_ROOT")}/admin/users`, { params: params })
        .then((response) => {
          this.userId = null;
          this.search.users = response.data.data;
        });
    }, 1500),

    loginAsUser() {
      axios
        .post(`${config("BACKEND_ROOT")}/admin/users/${this.userId}/login`)
        .then((response) => {
          const token = response.data.access_token;
          const refresh = response.data.refresh_token;

          window.open(`${config('FRONTEND_ROOT')}/auth/autologin?token=${token}&refresh=${refresh}&to=/explore/x/y/${this.experienceId}`, '_blank').focus();
        });
    },
  },
};
</script>

<style scoped>
.abExperiencesList {
  max-height: 700px;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>