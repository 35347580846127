<template>
  <div class="w-100">
    <h1 class="d-inline">Blackout Dates</h1>
    <button class="btn btn-success float-right" @click="showCreateModal">
      Create
    </button>

    <div class="table-responsive">
      <table class="table mt-4">
        <thead class="thead-light">
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Title</th>
            <th scope="col">Description</th>
            <th scope="col">Date Range</th>
            <th scope="col">Status</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="blackoutDate in items" v-bind:key="blackoutDate.id">
            <td>{{ blackoutDate.id }}</td>
            <td>{{ blackoutDate.title }}</td>
            <td :title="blackoutDate.description">
              {{ short(blackoutDate.description, 40) }}
            </td>
            <td>
              {{ getStartAndEndForHuman(blackoutDate) }}
            </td>
            <td>
              <span
                :class="blackoutDate.is_active ? 'text-success' : 'text-danger'"
              >
                {{ blackoutDate.is_active ? "Active" : "Inactive" }}
              </span>
            </td>
            <td>
              <button
                class="btn btn-sm btn-primary"
                title="Edit"
                @click="showUpdateModal(blackoutDate)"
              >
                <i class="fa fa-edit"></i>
              </button>
              <button
                class="btn btn-sm btn-danger ml-2"
                title="Delete"
                @click="deleteBlackoutDate(blackoutDate)"
              >
                <i class="fa fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <modal name="create-modal" height="auto">
      <div class="p-4">
        <button @click="$modal.hide('create-modal')" class="modal-close-button">
          ❌
        </button>
        <div class="h3 mb-4">Create a blackout date</div>
        <form action="">
          <select v-model="createForm.type" class="form-control" id="">
            <option value="date">Date Range</option>
            <option value="daysofweek">Days of Week</option>
          </select>

          <input
            v-model="createForm.title"
            type="text"
            class="form-control mt-3 mb-3"
            placeholder="Title"
            required
          />

          <textarea
            v-model="createForm.description"
            rows="3"
            class="form-control mt-3 mb-3"
            placeholder="Description..."
            required
          ></textarea>

          <div class="row">
            <div class="col-8" v-if="createForm.type == 'date'">
              <date-picker
                v-model="createForm.date_range"
                id="date-datepicker"
                locale="en"
                format="YYYY-MM-DD"
                range
                clearable
                color="#f42f4b"
                placeholder="Select a date range"
              >
              </date-picker>
            </div>

            <div class="col-8" v-if="createForm.type == 'daysofweek'">
              <div class="row">
                <div class="col-6">
                  <label for="createFormStartingDay">Starting Day:</label>
                  <select
                    v-model="createForm.starting_day"
                    class="form-control"
                    id="createFormStartingDay"
                    aria-label="Default select example"
                  >
                    <option
                      v-for="(key, day) of days"
                      v-bind:key="key"
                      :value="day"
                    >
                      {{ key }}
                    </option>
                  </select>
                </div>
                <div class="col-6">
                  <label for="createFormEndingDay">Ending Day:</label>
                  <select
                    v-model="createForm.ending_day"
                    class="form-control"
                    id="createFormEndingDay"
                  >
                    <option
                      v-for="(key, day) of days"
                      v-bind:key="key"
                      :value="day"
                    >
                      {{ key }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="col-4 pt-2 pl-4">
              <b-form-checkbox v-model="createForm.is_active" :style="createForm.type == 'daysofweek' ? 'padding-top:30px;' : ''">
                Active
              </b-form-checkbox>
            </div>
          </div>

          <ul
            style="padding-left: 20px; margin-top: 20px"
            v-if="createForm.errors.length"
          >
            <li
              v-for="error in createForm.errors"
              v-bind:key="error"
              class="text-danger"
            >
              {{ error }}
            </li>
          </ul>

          <button
            type="submit"
            class="btn btn-primary mt-4 w-100"
            @click.prevent="createBlackoutDate"
          >
            Create
          </button>
        </form>
      </div>
    </modal>

    <modal name="update-modal" height="auto">
      <div class="p-4">
        <button @click="$modal.hide('update-modal')" class="modal-close-button">
          ❌
        </button>
        <div class="h3 mb-4">Edit a blackout date</div>
        <form action="">
          <select v-model="updateForm.type" class="form-control" id="">
            <option value="date">Date Range</option>
            <option value="daysofweek">Days of Week</option>
          </select>

          <input
            v-model="updateForm.title"
            type="text"
            class="form-control mt-3 mb-3"
            placeholder="Title"
            required
          />

          <textarea
            v-model="updateForm.description"
            rows="3"
            class="form-control mt-3 mb-3"
            placeholder="Description..."
            required
          ></textarea>

          <div class="row">
            <div class="col-8" v-if="updateForm.type == 'date'">
              <date-picker
                v-model="updateForm.date_range"
                id="date-datepicker"
                locale="en"
                format="YYYY-MM-DD"
                range
                clearable
                color="#f42f4b"
                placeholder="Select a date range"
              >
              </date-picker>
            </div>

            <div class="col-8" v-if="updateForm.type == 'daysofweek'">
              <div class="row">
                <div class="col-6">
                  <label for="updateFormStartingDay">Starting Day:</label>
                  <select
                    v-model="updateForm.starting_day"
                    class="form-control"
                    id="updateFormStartingDay"
                    aria-label="Default select example"
                  >
                    <option
                      v-for="(key, day) of days"
                      v-bind:key="key"
                      :value="day"
                    >
                      {{ key }}
                    </option>
                  </select>
                </div>
                <div class="col-6">
                  <label for="updateFormEndingDay">Ending Day:</label>
                  <select
                    v-model="updateForm.ending_day"
                    class="form-control"
                    id="updateFormEndingDay"
                  >
                    <option
                      v-for="(key, day) of days"
                      v-bind:key="key"
                      :value="day"
                    >
                      {{ key }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="col-4 pt-2 pl-4">
              <b-form-checkbox v-model="updateForm.is_active" :style="updateForm.type == 'daysofweek' ? 'padding-top:30px;' : ''">
                Active
              </b-form-checkbox>
            </div>
          </div>

          <ul
            style="padding-left: 20px; margin-top: 20px"
            v-if="createForm.errors.length"
          >
            <li
              v-for="error in createForm.errors"
              v-bind:key="error"
              class="text-danger"
            >
              {{ error }}
            </li>
          </ul>

          <button
            type="submit"
            class="btn btn-primary mt-4 w-100"
            @click.prevent="updateBlackoutDate"
          >
            Edit
          </button>
        </form>
      </div>
    </modal>
  </div>
</template>

<script>
import { config, short } from "../helpers";

export default {
  name: "BlackoutDates",
  data() {
    return {
      url: "",
      items: [],

      days: {
        1: "Monday",
        2: "Tuesday",
        3: "Wednesday",
        4: "Thursday",
        5: "Friday",
        6: "Saturday",
        0: "Sunday",
      },

      createForm: {
        type: null,
        title: null,
        description: null,
        date_range: null,
        starting_day: null,
        ending_day: null,
        is_active: null,
        errors: [],
      },

      updateForm: {
        type: null,
        title: null,
        description: null,
        date_range: null,
        starting_day: null,
        ending_day: null,
        is_active: null,
        errors: [],
      },
    };
  },

  mounted() {
    this.url = config("BACKEND_ROOT") + "/admin/blackoutdates";
    this.loadData();
  },

  methods: {
    loadData() {
      axios.get(this.url).then((response) => {
        this.items = response.data.data;
      });
    },

    showCreateModal() {
      this.createForm = {
        type: "date",
        title: null,
        description: null,
        date_range: null,
        starting_day: null,
        ending_day: null,
        is_active: true,
        errors: [],
      };

      this.$modal.show("create-modal");
    },

    createBlackoutDate() {
      let startsAt, endsAt;
      if (this.createForm.type == 'date') {
        startsAt = this.createForm.date_range[0].substr(5);
        endsAt = this.createForm.date_range[1].substr(5);
      } else if (this.createForm.type == 'daysofweek') {
        startsAt = this.createForm.starting_day;
        endsAt = this.createForm.ending_day;
      }

      axios
        .post(this.url, {
          type: this.createForm.type,
          title: this.createForm.title,
          description: this.createForm.description,
          starts_at: startsAt,
          ends_at: endsAt,
          is_active: this.createForm.is_active,
        })
        .then((response) => {
          this.loadData();
          this.$modal.hide("create-modal");
        })
        .catch((error) => {
          this.createForm.errors = Object.values(
            error.response.data.errors
          ).flat();
        });
    },

    showUpdateModal(blackoutDate) {
      this.updateForm = {
        id: blackoutDate.id,
        type: blackoutDate.type,
        title: blackoutDate.title,
        description: blackoutDate.description,
        is_active: blackoutDate.is_active,
        errors: [],
      };

      if (blackoutDate.type == 'date') {
        this.updateForm.date_range = [
          new Date().getFullYear() + "-" + blackoutDate.starts_at,
          new Date().getFullYear() + "-" + blackoutDate.ends_at,
        ];
      } else if (blackoutDate.type == 'daysofweek') {
        this.updateForm.starting_day = blackoutDate.starts_at;
        this.updateForm.ending_day = blackoutDate.ends_at;
      }

      this.$modal.show("update-modal");
    },

    updateBlackoutDate() {
      let startsAt, endsAt;
      if (this.updateForm.type == 'date') {
        startsAt = this.updateForm.date_range[0].substr(5);
        endsAt = this.updateForm.date_range[1].substr(5);
      } else if (this.updateForm.type == 'daysofweek') {
        startsAt = this.updateForm.starting_day;
        endsAt = this.updateForm.ending_day;
      }

      axios
        .put(`${this.url}/${this.updateForm.id}`, {
          type: this.updateForm.type,
          title: this.updateForm.title,
          description: this.updateForm.description,
          starts_at: startsAt,
          ends_at: endsAt,
          is_active: this.updateForm.is_active,
        })
        .then((response) => {
          this.loadData();
          this.$modal.hide("update-modal");
        })
        .catch((error) => {
          this.updateForm.errors = Object.values(
            error.response.data.errors
          ).flat();
        });
    },

    deleteBlackoutDate(blackoutDate) {
      if (confirm("Are you sure about this?!")) {
        axios
          .delete(`${this.url}/${blackoutDate.id}`)
          .then((response) => {
            this.loadData();
          })
          .catch((error) => {
            alert("Something went wrong!");
          });
      }
    },

    short(string, limit) {
      return short(string, limit);
    },

    getStartAndEndForHuman(blackoutDate) {
      if (blackoutDate.type == "date") {
        return this.getDateRangeForHuman(
          blackoutDate.starts_at,
          blackoutDate.ends_at
        );
      }

      if (blackoutDate.type == "daysofweek") {
        return this.getDaysRangeForHuman(
          blackoutDate.starts_at,
          blackoutDate.ends_at
        );
      }

      return "Unknown";
    },

    getDateRangeForHuman(startsAt, endsAt) {
      let months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];

      return (
        months[startsAt.split("-")[0] - 1] +
        " " +
        startsAt.split("-")[1] +
        "  —  " +
        months[endsAt.split("-")[0] - 1] +
        " " +
        endsAt.split("-")[1]
      );
    },

    getDaysRangeForHuman(startsAt, endsAt) {
      return this.days[startsAt] + "  —  " + this.days[endsAt];
    },
  },
};
</script>

<style scoped>
.table {
  font-size: 14px !important;
}
</style>