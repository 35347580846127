<template>
  <div class="w-100">
    <h1 class="d-inline">coupons</h1>
    <button class="btn btn-success float-right" @click="showCreateModal">Create a coupon</button>

    <div class="table-responsive">
      <table class="table mt-4">
        <thead class="thead-light">
        <tr>
          <th scope="col" title="Name">Name</th>
          <th scope="col" title="Code">Code</th>
          <th scope="col" title="Percentage">%</th>
          <th scope="col" title="Maximum Discount">Max</th>
          <th scope="col" title="Currency">Currency</th>
          <th scope="col" title="Used">Used</th>
          <th scope="col" title="Usage Limit">Usage</th>
          <th scope="col" title="Status">Status</th>
          <th scope="col" title="Created at">Created at</th>
          <th scope="col" title="Actions"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="coupon in items" v-bind:key="coupon.id">
          <td class="text-left">{{ coupon.name }}</td>
          <td>{{ coupon.code }}</td>
          <td>{{ coupon.value }}</td>
          <td>{{ coupon.maximum_amount || '-' }}</td>
          <td>{{ coupon.currency_code || '-' }}</td>
          <td>{{ coupon.usage_count }}</td>
          <td>{{ coupon.usage_limit || '-' }}</td>
          <td>
            <b-form-checkbox
              v-model="coupon.is_active"
              name="check-button"
              switch
              size="lg"
              class="d-inline-block"
              @change="toggleCouponStatus(coupon)"
            >
            </b-form-checkbox>
          </td>
          <td>{{ formatDate(coupon.created_at) }}</td>
          <td>
            <button class="btn btn-sm btn-primary" title="Edit" @click="showUpdateModal(coupon)">
              <i class="fa fa-edit"></i>
            </button>
            <button class="btn btn-sm btn-danger ml-2" title="Delete" @click="deleteCoupon(coupon)">
              <i class="fa fa-trash"></i>
            </button>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <modal name="create-modal" height="auto">
      <div class="p-4">
        <button @click="$modal.hide('create-modal')" class="modal-close-button">❌</button>
        <div class="h3 mb-3">Create a coupon</div>
        <form action="">
          <div class="mb-3">
            <label class="d-inline">Name</label>
            <sup>*</sup>
            <input
                v-model="createForm.name"
                type="text"
                class="form-control"
                placeholder="Coupon Name"
            />
          </div>
          <div class="mb-3">
            <div class="mb-3">
              <label class="d-inline">Code</label>
              <sup>*</sup>
              <input
                  v-model="createForm.code"
                  type="text"
                  class="form-control"
                  required
              />
            </div>
          </div>

          <hr>
          <!-- Common fields for both types -->
          <div class="mb-3 row">
            <div class="col mr-1">
              <label class="d-inline">Percentage</label>
              <sup>*</sup>
              <input
                  v-model="createForm.value"
                  type="number"
                  min="0"
                  max="100"
                  class="form-control"
              />
            </div>
            <div class="col ml-1">
              <label class="d-inline">Maximum Usage</label>
              <input
                  v-model="createForm.usage_limit"
                  type="number"
                  min="1"
                  class="form-control"
              />
            </div>
          </div>
          <div class="row">
            <div class="col mr-1">
              <label class="d-inline ">Maximum Amount</label>
              <input
                  v-model="createForm.maximum_amount"
                  type="number"
                  min="0"
                  class="form-control"
              />
            </div>
            <div class="col ml-1">
              <label class="d-inline">Currency</label>
              <select v-model="createForm.currency_code" class="form-control">
                <option v-for="currency in currencies" v-bind:key="currency.currency_code" v-bind:value="currency.currency_code">
                  {{ currency.currency_code }}
                </option>
              </select>
            </div>
          </div>

          <ul style="padding-left: 20px; margin-top: 20px">
            <li v-for="error in errors" v-bind:key="error" class="text-danger">{{ error }}</li>
          </ul>

          <button type="submit" class="btn btn-primary mt-3 w-100" @click.prevent="createCoupon">
            Create
          </button>
        </form>
      </div>
    </modal>


    <modal name="update-modal" height="auto">
      <div class="p-4">
        <button @click="$modal.hide('update-modal')" class="modal-close-button">❌</button>
        <div class="h3 mb-3">Edit a coupon</div>
        <form action="">


          <div class="mb-3">
            <label class="d-inline">Name</label>
            <sup>*</sup>
            <input
                v-model="updateForm.name"
                type="text"
                class="form-control"
                placeholder="Name, some alternative text"
            />
          </div>

          <div class="mb-3 row">
            <div class="col">
              <label class="d-inline">Percentage</label>
              <sup>*</sup>
              <input
                  v-model="updateForm.value"
                  type="number"
                  min="0"
                  max="100"
                  class="form-control"
              />
            </div>
            <div class="col ml-1">
                <label class="d-inline">Maximum Usage</label>
                <input
                    v-model="updateForm.usage_limit"
                    type="number"
                    min="1"
                    class="form-control"
                />
            </div>
          </div>

          <div class="mb-3 row">
            <div class="col mr-1">
              <label class="d-inline">Maximum Amount</label>
              <input
                  v-model="updateForm.maximum_amount"
                  type="number"
                  min="0"
                  class="form-control"
              />
            </div>
            <div class="col ml-1">
              <label class="d-inline">Currency</label>
              <select v-model="updateForm.currency_code" class="form-control">
                <option v-for="currency in currencies" v-bind:key="currency.currency_code" v-bind:value="currency.currency_code">
                  {{ currency.currency_code }}
                </option>
              </select>
            </div>
          </div>

          <ul style="padding-left: 20px; margin-top: 20px">
            <li
                v-for="error in errors"
                v-bind:key="error"
                class="text-danger"
            >
              {{ error }}
            </li>
          </ul>

          <button type="submit" class="btn btn-primary mt-3 w-100" @click.prevent="updateCoupon">
            Edit
          </button>
        </form>
      </div>
    </modal>
  </div>
</template>

<script>
import {config, formatDate} from "../helpers";
import KeyValueInput from "../components/KeyValueInput.vue";
import FileUpload from '../components/FileUpload.vue';
import VueSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import axios from "axios";
import _ from "lodash";

export default {
  name: "coupons",
  components: {
    'key-value-input': KeyValueInput,
    'file-upload': FileUpload,
    'v-select': VueSelect,
  },
  data() {
    return {
      type: null, // to store the selected coupon type
      url: "",
      items: [],
      currencies: [],
      createForm: {},
      updateForm: {},
      errors: [],

    };
  },

  mounted() {
    this.url = config("BACKEND_ROOT") + "/admin/coupons";
    this.resetCreateForm();
    this.fetchCoupons();
    this.fetchCurrencies();
  },

  methods: {
    formatDate,
    fetchCoupons() {
      axios.get(this.url).then((response) => {
        this.items = response.data.data;
      });
    },

    fetchCurrencies() {
      axios.get(config("BACKEND_ROOT") + "/currencies").then((response) => {
        this.currencies = response.data.data;
      });
    },


    showCreateModal() {
      this.resetCreateForm();
      this.errors = [];
      this.$modal.show("create-modal");
    },

    createCoupon(e) {
      e.target.innerText = "Creating...";
      e.target.disabled = true;

      const payload = this.createForm;

      axios
          .post(this.url, payload)
          .then((response) => {
              this.fetchCoupons();
              this.$modal.hide("create-modal");
          })
          .catch((error) => {
            e.target.innerText = "Create";
            e.target.disabled = false;
            this.errors = Object.values(error.response.data.errors).flat();
          });
    },

    showUpdateModal(coupon) {
      this.updateForm = {
        id: coupon.id,
        name: coupon.name,
        value: coupon.value,
        currency_code: coupon.currency_code,
        maximum_amount: coupon.maximum_amount,
        usage_limit: coupon.usage_limit,
        created_at: coupon.created_at,
      };
      this.errors = [];
      this.$modal.show("update-modal");
    },

    updateCoupon(e) {
      e.target.innerText = "Editing...";
      e.target.disabled = true;

      const payload = this.updateForm;

      axios
          .put(`${this.url}/${this.updateForm.id}`, payload)
          .then((response) => {
            this.$modal.hide("update-modal");
            this.fetchCoupons();
          })
          .catch((error) => {
            e.target.innerText = "Edit";
            e.target.disabled = false;
            this.errors = Object.values(error.response.data.errors).flat();
          });
    },

    toggleCouponStatus(coupon) {
      axios
          .put(`${this.url}/${coupon.id}`, { is_active: coupon.is_active })
          .then((response) => {
            this.fetchCoupons();
          })
          .catch((error) => {
            alert('Something went wrong!');
          });
    },

    deleteCoupon(coupon) {
      if (confirm("Are you sure about this?!")) {
        axios
            .delete(`${this.url}/${coupon.id}`)
            .then((response) => {
              this.fetchCoupons();
            })
            .catch((error) => {
              alert('Something went wrong!');
            });
      }
    },

    resetCreateForm() {
      this.createForm = {
          code: null,
          name: null,
          value: null,
          currency_code: null,
          maximum_amount: null,
          usage_limit: null,
      }
    },
  }
};
</script>


<style scoped>
.table {
  font-size: 14px !important;
}
.th {
  text-align: center
}
.td {
  text-align: center
}
.selection-container {
   display: flex;
   height: 200px;
   overflow: hidden;
   border-radius: 8px;
   box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
 }

.selection-item {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.selection-item:hover {
  background-color: #e0e0e0; /* Light grey on hover */
  transform: scale(1.05);
}

.selection-item.product {
  background-color: #f5f5f5; /* Very light grey */
}

.selection-item.url {
  background-color: #dcdcdc; /* Light grey */
}

.selection-text {
  color: #333; /* Dark grey for text */
  font-size: 1.5em;
  text-align: center;
  z-index: 1; /* Ensure text is above any background */
}
label {
  font-size: .8em;
  font-weight: bold;
  /* margin-bottom: 0.5em; */
}
</style>
