<template>
  <div class="w-100">
    <div class="data" v-if="loaded">
      <h1 class="d-inline">Promotion {{ item.id }}</h1>
      <hr />

      <div class="row text-center pl-4 pr-4 mt-5 mb-5">
        <div class="col-lg-3">
          <div>
            <strong>Promotion Id</strong>
            <br />
            <div>{{ item.id }}</div>
          </div>
        </div>
        <div class="col-lg-3">
          <div>
            <strong>Code</strong>
            <br />
            <div  style="color: #f42f4b; font-weight: bold">
              {{ item.code }}
            </div>
          </div>
        </div>

        <div class="col-lg-3">
          <div>
            <strong>Start at</strong>
            <br />
            <div
              style="color: #f42f4b; cursor: pointer"
            >
              {{ moment(item.start_at).format('YYYY-MM-DD hh:mm:ss') }}
            </div>
          </div>
        </div>

        <div class="col-lg-3">
          <div>
            <strong>Expire at</strong>
            <br />
            <div  style="color: #f42f4b; cursor: pointer"
            >
              {{ moment(item.expire_at).format('YYYY-MM-DD hh:mm:ss')}}
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div class="table-responsive">
        <table class="table mt-2">
          <thead class="thead-light">
            <tr>
              <th scope="col">Name</th>
              <th scope="col">Email</th>
              <th scope="col">Phone number</th>
              <th scope="col">Promotion amount</th>
              <th scope="col">Status</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="cart in item.carts" v-bind:key="cart.id">
              <td>{{ `${cart.user.first_name+' '+cart.user.last_name}` }}</td>
              <td>{{ cart.user.email}}</td>
              <td>
                {{ cart.user.phone_number}}
              </td>
              <td>
                {{ `${cart.promotion_amount} AED` }}
              </td>
              <td>{{ ! item.status ? "Used" : "UnUsed" }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="mt-5 pt-5">
      <loading v-if="!loaded"></loading>
    </div>
  </div>
</template>

<script>
import { config } from "../helpers";
import moment from "moment";

export default {
  data() {
    return {
      id: this.$route.params.id,
      loaded: false,
      item: null,
    };
  },

  mounted() {
    this.url = config("BACKEND_ROOT") + "/admin/promotions";
    this.loadData();
  },
  computed: {
    moment() {
      return moment
    }
  },

  methods: {
    loadData() {
      axios.get(`${this.url}/${this.id}`).then((response) => {
        this.item = response.data.data;
        this.loaded = true;
      });
    },
  },
};
</script>

<style scoped>
.cart-confirm {
  position: fixed;
  bottom: 0;
}
</style>