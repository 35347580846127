<template>
  <div class="w-100">
    <h1>Purchase history for the user #{{ this.id }}</h1>

    <div class="table-responsive" v-if="this.loaded">
      <table class="table mt-4">
        <thead class="thead-light">
          <tr>
            <th scope="col">Type</th>
            <th scope="col">Title</th>
            <th scope="col">Created at</th>
          </tr>
        </thead>
        <tbody v-for="(item, index) in items" v-bind:key="index" @click.prevent="$router.push(`/carts/${item.cart_id}`)" style="cursor: pointer;">
          <td v-html="getTypeBadge(item.type)"></td>
          <td>{{ item.title }}</td>
          <td>{{ formatDate(item.created_at) }}</td>
        </tbody>
      </table>
    </div>

    <div class="mt-5 pt-5">
      <loading v-if="!loaded"></loading>
    </div>
  </div>
</template>

<script>
import { config } from "../helpers";

export default {
  name: "UserPurchases",
  data() {
    return {
      url: "",
      id: this.$route.params.id,
      items: [],
      loaded: false,
    };
  },

  mounted() {
    this.url = config("BACKEND_ROOT") + "/admin/users";
    this.loadData();
  },

  methods: {
    loadData() {
      axios.get(`${this.url}/${this.id}/purchases`).then((response) => {
        this.items = response.data.data;
        this.loaded = true;
      });
    },

    formatDate(date) {
      if (! date) return null;

      date = new Date(date);
      
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes()
      );
    },

    getTypeBadge(type) {
      if (type == "Experience") {
        return '<span class="badge badge-success">Experience</span>';
      } else {
        return '<span class="badge badge-primary">Gift</span>';
      }
    },
  },
};
</script>

<style scoped>
.table {
  font-size: 14px !important;
}
</style>