<template>
  <div class="w-100">
    <h1>Corporate Edit</h1>

    <form action="https://google.com" v-if="this.loaded">
      <div class="form-row">
        <div class="form-group col-lg-6">
          <label for="firstnameInput">Firstname</label>
          <input
            type="text"
            v-model="corporate.user.first_name"
            class="form-control"
            id="firstnameInput"
            required
          />
        </div>
        <div class="form-group col-lg-6">
          <label for="lastnameInput">Lastname</label>
          <input
            v-model="corporate.user.last_name"
            class="form-control"
            id="lastnameInput"
            required
          />
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-lg-4">
          <label for="companyInput">Company name</label>
          <input
            v-model="corporate.name"
            class="form-control"
            id="companyInput"
            required
          />
        </div>
        <div class="form-group col-lg-4">
          <label for="websiteInput">Website url</label>
          <input
            v-model="corporate.website"
            class="form-control"
            id="websiteInput"
            required
          />
        </div>
        <div class="form-group col-lg-4">
          <label for="budgetInput">Budget</label>
          <input
            v-model="corporate.budget"
            class="form-control"
            id="budgetInput"
            required
          />
        </div>
      </div>

      <div class="form-row mt-2">
        <div class="form-group col-lg-6">
          <label for="emailInput">Email</label>
          <input
            v-model="corporate.user.email"
            type="email"
            class="form-control"
            id="emailInput"
            required
          />
        </div>
        <div class="form-group col-lg-6">
          <label for="phoneNumberInput">Phone number</label>
          <input
            v-model="corporate.user.phone_number"
            class="form-control"
            id="phoneNumberInput"
            required
          />
        </div>
      </div>

      <hr />

      <div class="form-row mt-3">
        <b-form-group label="Services:" v-slot="{ ariaServices }">
          <b-form-checkbox-group
            id="checkbox-group-1"
            v-model="corporate.services"
            :options="services"
            :aria-describedby="ariaServices"
            name="flavour-1"
          ></b-form-checkbox-group>
        </b-form-group>
      </div>

      <hr />

      <div class="form-row mt-3">
        <div class="form-group col-lg-6">
          <country-search-box :name="this.corporate.user.address.country.name" @selected="(id) => corporate.user.address.country_id = id"></country-search-box>
        </div>
        <div class="form-group col-lg-6">
          <city-search-box :name="corporate.user.address.city.name" @selected="(id) => corporate.user.address.city_id = id"></city-search-box>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-lg-8">
          <label for="addressInput">Address</label>
          <input
            v-model="corporate.user.address.address"
            class="form-control"
            id="addressInput"
            required
          />
        </div>
        <div class="form-group col-lg-4">
          <label for="postcodeInput">Postcode</label>
          <input
            v-model="corporate.user.address.post_code"
            class="form-control"
            id="postcodeInput"
          />
        </div>
      </div>

      <ul style="padding-left: 20px; margin-top: 20px">
        <li
          v-for="error in corporate.errors"
          v-bind:key="error"
          class="text-danger"
        >
          {{ error }}
        </li>
      </ul>

      <button
        type="submit"
        class="btn btn-primary pl-4 pr-4 mt-3 mb-3"
        @click="UpdateCorporate"
      >
        Edit
      </button>
    </form>
  </div>
</template>

<script>
import { config } from "../helpers";
import CountrySearchBox from "../components/CountrySearchBox.vue";
import CitySearchBox from "../components/CitySearchBox.vue";

export default {
  name: "CorporateEdit",
  components: {
    "country-search-box": CountrySearchBox,
    "city-search-box": CitySearchBox,
  },
  data() {
    return {
      id: this.$route.params.id,
      url: "",
      loaded: false,

      services: [
        "Rewards & Recognition",
        "Long Service Awards",
        "Motivation",
        "On Pack Promotions",
        "Corporate Gifts",
        "Team Building",
        "Sales Incentives",
        "Themed Incentives",
        "Corporate Hospitality",
        "Marketing Promotions",
        "Referral Schemes",
        "Employee Benefits",
        "Prizes",
      ],
      corporate: { user: { first_name: "", address: { address: "" } } },
    };
  },

  mounted() {
    this.url = config("BACKEND_ROOT") + '/admin/corporates';
    this.loadData();
  },

  methods: {
    loadData() {
      axios.get(`${this.url}/${this.id}`).then((response) => {
        this.corporate = response.data.data;
        this.corporate.user.address.country_id = this.corporate.user.address.country.id;
        this.corporate.user.address.city_id = this.corporate.user.address.city.id;
        this.loaded = true;
      });
    },

    UpdateCorporate(event) {
      event.preventDefault();
      axios
        .put(`${this.url}/${this.id}`, this.corporate)
        .then((response) => {
          this.$router.push(`/corporates/`);
        })
        .catch((error) => {
          alert(Object.values(error.response.data.errors).flat()[0]);
        });
    },

    formatDate(date) {
      date = new Date(date);
      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes()
      );
    },
  },
};
</script>

<style scoped>
.table {
  font-size: 14px !important;
}
</style>