<template>
    <div class="w-100">
      <h1>Recommendations List</h1>
      <button class="btn btn-success float-right" @click="$router.push('/recommendations/create')">Create</button>

    <div class="table-responsive">
      <table class="table mt-2">
        <thead class="thead-light">
          <tr>
            <th scope="col">Id</th>
            <th scope="col">Title</th>
            <th scope="col">Priority</th>
            <th scope="col">Status</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
        <tr  v-for="recommendation in items" v-bind:key="recommendation.id">
          <td>{{ recommendation.id }}</td>
          <td>{{ recommendation.title }}</td>
          <td>{{ recommendation.priority }}</td>
          <td>{{ recommendation.is_enabled ? 'active' : 'deactive' }}</td>
          <td>
            <router-link
                :to="{ name: 'recommendations.show', params: { id: recommendation.id } }"
                style="cursor: pointer">
              <i class="fa fa-edit"></i>
            </router-link>
            <a class="ml-2" title="Delete" @click="deleteRecommendation(recommendation.id)">
                <i class="fa fa-trash" style="color: red; cursor: pointer"></i>
              </a>
          </td>
        </tr>

        </tbody>
      </table>
    </div>

    <nav>
      <ul class="pagination">
        <b-pagination v-model="page" :total-rows="totalItems" :per-page="perPage" last-number></b-pagination>
      </ul>
    </nav>
  
    </div>
  </template>
  
<script>
import { config } from "../../helpers";

export default {
  data() {
    return {
      url: "",
      items: [],
      inputFilters: {
        q: "",
        date: [],
      },
      isFiltered: false,
      filters: {},
      page: 1,
      lastPage: 1,
      totalItems: 1,
      perPage: 1,
    };
  },

  mounted() {
    this.url = config("BACKEND_ROOT") + "/admin/recommendations";
    this.loadData();
  },

  methods: {
    loadData() {
      let myData = { page: this.page };

      if (this.isFiltered) {
        myData = { ...myData, date: this.filters.date, q: this.filters.q };
      }

      axios.get(this.url, { params: myData }).then((response) => {
        this.items = response.data.data;
        this.page = response.data.meta.current_page;
        this.lastPage = response.data.meta.last_page;
        this.totalItems = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
      });
    },

    deleteRecommendation(id){
      axios.delete(this.url+'/'+id).then((response) => {
        this.loadData();
      });
    },

    filter() {
      this.page = 1;
      this.isFiltered = true;
      let filters = {};

      if (this.inputFilters.q) {
        filters.q = this.inputFilters.q;
      }

      this.filters = filters;

      this.loadData();
    },

  },

  watch: {
    page: function (val) {
      this.loadData();
    },
  },
};
</script>
  
  <style scoped>
  .table {
    font-size: 14px !important;
  }
  </style>