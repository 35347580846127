<template>
  <div>
    <div v-for="i in keysCount" v-bind:key="i" class="mb-1">
      <input
        v-model="keys[i]"
        @input="update()"
        :placeholder="getExampleKey(i)"
        class="form-control d-inline w-25"
      />
      :
      <input
        v-model="values[i]"
        @input="update()"
        :placeholder="getExampleValue(i)"
        class="form-control d-inline w-50"
      />
      <i class="fa fa-minus h5 d-inline ml-3 cursor-pointer" @click="removeInput(i)"></i>
    </div>

    <i class="fa fa-plus h5 mt-1 mr-3 cursor-pointer" @click="keysCount++"></i>
  </div>
</template>

<script>
export default {
  props: ["value", "editData"],

  data() {
    return {
      keysCount: 2,
      keys: ['', 'title', /*'category', 'price',*/ 'product_id'],
      values: [],
    };
  },

  mounted() {
    if (this.editData) {
      this.keys = [];
      this.values = [];
      this.keys.push(null);
      this.values.push(null);
      this.keysCount = Object.keys(this.editData).length;
      for (const [key, value] of Object.entries(this.editData)) {
        this.keys.push(key);
        this.values.push(value);
      }
    }
  },

  methods: {
    update() {
      let obj = {};
      for (let i = 1; i <= this.keysCount; i++) {
        if (this.keys[i] && this.values[i]) {
            obj[this.keys[i].toLowerCase().trim()] = this.values[i];
        }
      }

      this.editData = JSON.parse(JSON.stringify(obj));
      this.$emit("input", obj);
    },

    removeInput(inputId) {
        this.keys.splice(inputId, 1);
        this.values.splice(inputId, 1);
        this.keysCount--;
        this.update();
    },

    getExampleKey(i) {
      if (i > 6) return "";

      return ["title", /*"category", "price",*/ "product_id"/*, "city", "color", "discount"*/][i - 1];
    },

    getExampleValue(i) {
      if (i > 6) return "";

      return ["My banner", /*"Hotels", "1000",*/ 320/*, "Dubai", "Red", "24%"*/][i - 1];
    },
  },
};
</script>
