<template>
  <div class="w-100">
    <div class="data" v-if="loaded">
      <h1 class="d-inline">Transaction #{{ transaction.id }}</h1>
      <div class="mt-2">
        <span class="text-secondary">
          <i class="fa fa-calendar mr-1"></i> {{ formatDate(transaction.last_update) }}
        </span>
        <span class="float-right" v-html="getStatusBadge"></span>
      </div>

      <hr/>

      <div class="row text-center pl-4 pr-4 mt-5 mb-5">

        <div class="col-lg-3">
          <div>
            <strong>Transaction Reference</strong>
            <br/>
            {{ transaction.transaction_reference }}
          </div>
        </div>
        <div class="col-lg-3">
          <div>
            <strong>Cart ID</strong>
            <br/>
            <div
                @click.prevent="$router.push(`/carts/${transaction.cart.id}`)"
                style="color: #f42f4b; cursor: pointer"
            >
              {{ transaction.cart.id }}
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div>
            <strong>Payment Method</strong><br/>
            <div>
              {{ transaction.payment_method }}
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div>
            <strong>Amount</strong><br/>
            <div>
              {{ transaction.amount + " " + transaction.currency }}
            </div>
          </div>
        </div>

      </div>

      <div class="row text-center pl-4 pr-4 mt-5 mb-4">
        <div class="col-lg-3">
          <div>
            <strong>Customer ID</strong>
            <br/>
            <div
                @click.prevent="$router.push(`/users/${transaction.cart.user.id}`)"
                style="color: #f42f4b; cursor: pointer"
            >
              {{ transaction.cart.user.id }}
            </div>
          </div>
        </div>
        <div class="col-lg-3">
          <div>
            <strong>Customer Name</strong><br/>{{
              transaction.cart.user.first_name + " " + transaction.cart.user.last_name
            }}
          </div>
        </div>
        <div class="col-lg-3">
          <div><strong>Customer Email</strong><br/>{{ transaction.cart.user.email }}</div>
        </div>
        <div class="col-lg-3">
          <div><strong>Customer Phone</strong><br/>{{ transaction.cart.user.phone_number }}</div>
        </div>
      </div>

      <hr/>

      <div class="table-responsive">
        <table class="table mt-2">
          <thead class="thead-light">
          <tr>
            <th scope="col">ID</th>
            <th scope="col">Product Title</th>
            <th scope="col">Order ID</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(item, index) in transaction.cart.items" v-bind:key="item.id" :class="item.meta.is_refunded ? 'refunded' : ''">
            <td>{{ item.id }}</td>
            <td>{{ item.meta.title }} <span v-if="item.options.pay_later">🏠</span></td>
            <td>
              <a
                  v-if="item.meta.order_id"
                  @click.prevent="$router.push(`/orders/${item.meta.order_id}`)"
                  class="cursor-pointer"
              >{{ item.meta.order_id }}</a>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="mt-5 pt-5">
      <loading v-if="!loaded"></loading>
    </div>
  </div>
</template>

<script>
import ConfirmModal from "../components/ViewComponents/Carts/ConfirmModal.vue";
import CartLogsModal from "../components/ViewComponents/Carts/Logs.vue";
import {config} from "../helpers";

export default {
  name: "Transaction",
  components: {
    "confirm-modal": ConfirmModal,
    "logs-modal": CartLogsModal,
  },
  data() {
    return {
      id: this.$route.params.id,
      loaded: false,
      transaction: null,

      selectedPaymentMethod: null,

      cpItemId: null,
      cpNewQuantity: null,
    };
  },

  mounted() {
    this.url = config("BACKEND_ROOT") + "/admin/transactions";
    this.loadData();
  },

  methods: {
    loadData() {
      axios.get(`${this.url}/${this.id}`).then((response) => {
        this.transaction = response.data.data;
        this.loaded = true;
      });
    },

    formatDate(date) {
      if (!date) return null;

      date = new Date(date);

      return (
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate() +
          " " +
          date.getHours() +
          ":" +
          date.getMinutes()
      );
    }
  },

  computed: {
    getStatusBadge() {
      if (this.transaction.status === 'success') {
        return '<span class="badge badge-success">Success</span>';
      }
      if (this.transaction.status === 'pending') {
        return '<span class="badge badge-warning">Pending</span>';
      }
      if (this.transaction.status === 'refund') {
        return '<span class="badge badge-primary">Refund</span>';
      } else {
        return '<span class="badge badge-danger">Failed</span>';

      }
    },
  }
};
</script>

<style scoped>
.cart-confirm {
  position: fixed;
  bottom: 0;
}

.refunded {
  background: rgb(147, 51, 51);
  color: #fff;
}
</style>
