<template>
  <div class="position-relative">
    <i :class="'searchbox__icon fa ' + iconClass"></i>
    <input
      v-model="inputText"
      :placeholder="placeholder || 'Search...'"
      type="text"
      class="searchbox__input form-control"
      @keyup="inputChange"
      @focus="$event.target.select(); iconClass = 'fa-search';"
      @blur="iconClass = resultSelected ? 'fa-check' : 'fa-search'"
    />

    <select class="searchbox__results custom-select" v-show="showResults" size="4">
      <option
        v-for="result of resultsVar"
        :value="result"
        v-bind:key="result"
        @click="selectResult(result)"
      >
        {{ result }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  props: ["results", "placeholder"],

  data() {
    return {
      inputText: "",
      showResults: false,
      iconClass: 'fa-search',
      resultSelected: false,

      resultsVar: this.results,
    };
  },

  mounted() {},

  methods: {
    inputChange() {
      this.showResults = false;
      this.resultSelected = false;

      if (this.inputText.length < 2) {
        return;
      }

      this.$emit("typing", this.inputText);
      this.showResults = this.resultsVar ? true : false;
    },

    selectResult(result) {
      this.inputText = result;
      this.iconClass = 'fa-check';
      this.resultsVar = [];
      this.showResults = false;
      this.resultSelected = true;
      this.$emit("selected", result);
    },
  },

  watch: {
    results(newValue) {
      this.resultsVar = newValue;
    }
  }
};
</script>

<style scoped>
.searchbox__icon {
  position: absolute;
  color: #bbb;
  font-size: 13px;
  top: 13px;
  left: 12px;
}

.searchbox__input {
  padding-left: 32px;
}

.searchbox__results {
  position: absolute;
  z-index: 999;
  padding: 0 4px;
}

.searchbox__results option {
  padding: 6px 3px 6px 8px;
}
.searchbox__results option:hover {
  background: #eee;
  color: #000;
}
</style>