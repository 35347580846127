<template>
  <div class="p-4">
    <button
      @click="$modal.hide('add-blackout-date-modal')"
      class="modal-close-button"
    >
      ❌
    </button>

    <div v-if="abStep == 1">
      <div class="h4 mb-4">Select blackout dates</div>
      <div class="atCategoriesList pl-3">
        <div class="row">
          <div class="list-group w-100 d-inline-block mt-2">
            <label
              v-for="blackoutDate in blackoutDates"
              v-bind:key="blackoutDate.id"
              :class="
                (blackoutDate.is_active ? '' : 'text-danger') +
                ' list-group-item pl-5 w-50 d-inline-block'
              "
            >
              <input
                v-model="abSelectedBlackoutDates"
                class="form-check-input me-1"
                type="checkbox"
                :value="blackoutDate.id"
              />
              {{ blackoutDate.title }}
            </label>
          </div>
        </div>
      </div>
      <button class="btn btn-primary float-right mb-3" @click="abStep++">
        Next »
      </button>
    </div>

    <div v-if="abStep == 2">
      <div class="h4 mb-4">Select categories</div>
      <div class="atCategoriesList pl-3">
        <div class="row">
          <div class="list-group w-100 d-inline-block mt-2">
            <label
              v-for="category in categories"
              v-bind:key="category.id"
              class="list-group-item pl-5 w-50 d-inline-block"
            >
              <input
                v-model="abSelectedCategory"
                class="form-check-input me-1"
                type="radio"
                :value="category.id"
              />
              {{ category.title }}
            </label>
          </div>
        </div>
      </div>
      <button class="btn btn-primary float-right mb-3" @click="abGoToStep3">
        Next »
      </button>
    </div>

    <div v-if="abStep == 3">
      <div class="h4 mb-3">Select experiences</div>
      <div class="abExperiencesList mb-3">
        <div class="row">
          <div class="list-group w-100 d-inline-block mt-2">
            <label
              v-for="experience in abExperiences"
              v-bind:key="experience.id"
              class="list-group-item pl-5 w-50 d-inline-block"
            >
              <input
                v-model="abSelectedExperiences"
                class="form-check-input me-1"
                type="checkbox"
                @change="checkExperienceCheckbox($event)"
                :value="experience.id"
                :placeholder="experience.title"
              />
              {{ experience.title }}
            </label>
          </div>
        </div>
      </div>

      <div class="mb-4">
        <b-badge
          href="#"
          v-for="experience in abSelectedExperiencesChips"
          v-bind:key="experience.id"
          variant="primary"
          class="mr-2"
          @click="checkExperienceChip($event)"
          :placeholder="experience.id"
          >{{ experience.title }} x</b-badge
        >
      </div>

      <button
        class="btn btn-primary float-left mb-3"
        @click="
          abStep--;
          abSelectedCategory = null;
          abSelectedExperiences = [];
          abSelectedExperiencesChips = [];
        "
      >
        « Previous
      </button>
      <button
        class="btn btn-success float-right mb-3"
        @click="addBlackoutDates"
      >
        Done
      </button>
    </div>
  </div>
</template>

<script>
import { config } from "../../../helpers";

export default {
  props: ["blackoutDates", "categories"],

  data() {
    return {
      abStep: 1,
      abSelectedCategory: null,
      abExperiences: [],
      abSelectedExperiences: [],
      abSelectedExperiencesChips: [],
      abSelectedBlackoutDates: [],
    };
  },

  methods: {
    addBlackoutDates() {
      axios
        .post(config("BACKEND_ROOT") + "/admin/blackoutdate-product", {
          product_ids: this.abSelectedExperiences,
          blackoutdate_ids: this.abSelectedBlackoutDates,
        })
        .then((response) => {
          this.$modal.hide('add-blackout-date-modal');
          this.$emit("done");
        });
    },

    abGoToStep3() {
      if (!this.abSelectedCategory) {
        return alert("You haven't selected any categories!");
      }

      let myData = {
        per_page: 100,
        filters: {
          category: { ids: this.abSelectedCategory },
        },
      };

      axios
        .get(config("BACKEND_ROOT") + "/admin/products", { params: myData })
        .then((response) => {
          this.abExperiences = response.data.data;
          this.abStep = 3;
        });
    },

    checkExperienceCheckbox(e) {
      if (e.path[0].checked) {
        this.abSelectedExperiencesChips.push({
          id: e.path[0]._value,
          title: e.path[0].attributes.placeholder.value,
        });
      } else {
        this.abSelectedExperiencesChips =
          this.abSelectedExperiencesChips.filter(
            (el) => el.id != e.path[0]._value
          );
      }
    },

    checkExperienceChip(e) {
      this.abSelectedExperiencesChips = this.abSelectedExperiencesChips.filter(
        (el) => el.id != e.path[0].attributes.placeholder.value
      );
      this.abSelectedExperiences = this.abSelectedExperiences.filter(
        (el) => el != e.path[0].attributes.placeholder.value
      );
    },
  },
};
</script>

<style scoped>
.abExperiencesList {
  max-height: 700px;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>