<template>
  <div class="w-100">
    <h1>Transactions List</h1>

    <div class="row mt-4">
      <div class="col-lg-4 mb-4" style="margin-top: 1px;">
        <label>Search by user id, name, or Transactions reference:</label>
        <input
            placeholder="Search..."
            v-model="inputFilters.q"
            class="form-control"
            v-on:keyup.enter="filter"
        />
      </div>
      <div class="col-lg-4">
        <label for="date-datepicker">Last update date:</label>
        <date-picker
            v-model="inputFilters.date"
            id="date-datepicker"
            locale="en"
            format="YYYY-MM-DD"
            range
            clearable
            color="#f42f4b"
        />
      </div>
      <div class="col-lg-2">
        <label for="status">Transactions status:</label>
        <select v-model="inputFilters.status" id="status" class="form-control">
          <option value="pending">Pending</option>
          <option value="success">Success</option>
          <option value="failed">Failed</option>
          <option value="refund">Refund</option>
        </select>
      </div>
      <!-- <div class="col-lg-2">
        <label for="status">Buyer type:</label>
        <select v-model="inputFilters.buyer_type" id="buyer_type" class="form-control">
          <option value="customer">Customer</option>
          <option value="admin">Admin</option>
        </select>
      </div> -->
      <div class="col-lg-2">
        <label class="d-block" style="visibility: hidden">Filter</label>
        <button class="btn btn-primary" @click="filter">
          <i class="fas fa-search"></i>
        </button>
      </div>
    </div>

    <div class="table-responsive">
      <table class="table mt-2">
        <thead class="thead-light">
        <tr>
          <th scope="col">Transaction ID</th>
          <th scope="col">Transaction reference</th>
          <th scope="col">Customer ID</th>
          <th scope="col">Customer Name</th>
          <th scope="col">Amount</th>
          <th scope="col">Last Update</th>
          <th scope="col">Payment Method</th>
          <th scope="col">Status</th>
        </tr>
        </thead>
        <tbody>
        <router-link tag="tr" :to="{ name: 'transaction.show', params: {id: transaction.id}}" v-for="transaction in items" v-bind:key="transaction.id" style="cursor:pointer;">
          <td>{{ transaction.id }}</td>
          <td>{{ transaction.transaction_reference }}</td>
          <td>{{ transaction.cart.user.id }}</td>
          <td>{{ transaction.cart.user.first_name + " " + transaction.cart.user.last_name }}</td>
          <td>{{ transaction.amount + " " + transaction.currency }}</td>
          <td>{{ formatDate(transaction.last_update) }}</td>
          <td>{{transaction.payment_method }}</td>

          <td v-html="getStatusBadge(transaction.status)"></td>
        </router-link>
        </tbody>
      </table>
    </div>

    <nav>
      <ul class="pagination">
        <b-pagination v-model="page" :total-rows="totalItems" :per-page="perPage" last-number></b-pagination>
      </ul>
    </nav>
  </div>
</template>

<script>
import {config} from "../helpers";

export default {
  name: "Transactions",
  data() {
    return {
      url: "",
      items: [],
      inputFilters: {
        q: null,
        status: null,
        buyer_type: null,
        date: [],
      },
      isFiltered: false,
      filters: {},
      page: 1,
      lastPage: 1,
      totalItems: 1,
      perPage: 1,
    };
  },

  mounted() {
    this.url = config("BACKEND_ROOT") + "/admin/transactions";
    this.loadData();
  },

  methods: {
    loadData() {
      let myData = {page: this.page, sort_by: "updated_at"};

      if (this.isFiltered) {
        myData = {...myData, q: this.filters.q, date: this.filters.date, status: this.filters.status};
      }

      axios.get(this.url, {params: myData}).then((response) => {
        this.items = response.data.data;
        this.page = response.data.meta.current_page;
        this.lastPage = response.data.meta.last_page;
        this.totalItems = response.data.meta.total;
        this.perPage = response.data.meta.per_page;
      });
    },

    filter() {
      this.page = 1;
      this.isFiltered = true;
      let filters = {};

      if (this.inputFilters.q) {
        filters.q = this.inputFilters.q;
      }

      if (this.inputFilters.date[0]) {
        filters.date = {};
        filters.date.start = this.inputFilters.date[0];
        filters.date.end = this.inputFilters.date[1];
      }

      if (this.inputFilters.status) {
        filters.status = this.inputFilters.status;
      }

      this.filters = filters;

      this.loadData();
    },

    formatDate(date) {
      if (!date) return null;

      date = new Date(date);

      return (
          date.getFullYear() +
          "-" +
          (date.getMonth() + 1) +
          "-" +
          date.getDate() +
          " " +
          date.getHours() +
          ":" +
          date.getMinutes()
      );
    },

    getStatusBadge(status) {
      if (status === 'success') {
        return '<span class="badge badge-success">Success</span>';
      } if (status === 'pending') {
        return '<span class="badge badge-warning">Pending</span>';
      }  if (status === 'refund') {
        return '<span class="badge badge-primary">Refund</span>';
      } else {
        return '<span class="badge badge-danger">Failed</span>';

      }
    },
  },

  watch: {
    page: function (val) {
      this.loadData();
    },
  },
};
</script>

<style scoped>
.table {
  font-size: 14px !important;
}
</style>