<template>
  <div class="w-100">
    <div class="data" v-if="loaded">
      <h1 class="d-inline">Cart #{{ cart.id }}</h1>
      <div class="mt-2">
        <span class="text-secondary">
          <i class="fa fa-calendar mr-1"></i> {{ formatDate(cart.last_update) }}
        </span>
        <span class="text-secondary ml-4" title="Buyer email">
          <i class="fa fa-user mr-1"></i>
          {{
            `${cart.meta.buyer_email || "-"} (${cart.meta.buyer_type || "-"})`
          }}
        </span>

        <span class="float-right" v-html="getStatusBadge"></span>
      </div>

      <hr />

      <div class="row text-center pl-4 pr-4 mt-5 mb-5">
        <div class="col-lg-3">
          <div>
            <strong>Customer ID</strong>
            <br />
            <div
              @click.prevent="$router.push(`/users/${cart.user.id}`)"
              style="color: #f42f4b; cursor: pointer"
            >
              {{ cart.user.id }}
            </div>
          </div>
        </div>

        <div class="col-lg-3">
          <div>
            <strong>Customer Name</strong><br />{{
              cart.user.first_name + " " + cart.user.last_name
            }}
          </div>
        </div>

        <div class="col-lg-3">
          <div><strong>Customer Email</strong><br />{{ cart.user.email }}</div>
        </div>

        <div class="col-lg-3">
          <div>
            <strong>Customer Phone</strong><br />{{ cart.user.phone_number }}
          </div>
        </div>

        <div class="col-lg-3" v-if="cart.meta.invoice_id">
          <div>
            <strong>Invoice Code</strong><br />
            <div
              @click.prevent="$router.push(`/invoices/${cart.meta.invoice_id}`)"
              style="color: #f42f4b; cursor: pointer"
            >
              {{ cart.meta.invoice_code }}
            </div>
          </div>
        </div>
      </div>

      <div class="row text-center pl-4 pr-4 mt-5 mb-4">
        <div class="col-lg-4" v-if="cart.meta.payment_method">
          <div>
            <strong>Payment Method</strong><br />
            <div>
              {{
                Array.isArray(cart.meta.payment_method)
                  ? cart.meta.payment_method.join(",")
                  : cart.meta.payment_method
              }}
            </div>
            <div
              v-text="
                cart.meta.reference
                  ? cart.meta.reference.tap_id || cart.meta.reference.guid
                  : ''
              "
            ></div>
          </div>
        </div>
      </div>

      <hr />

      <div class="table-responsive">
        <table class="table mt-2">
          <thead class="thead-light">
            <tr>
              <th scope="col">ID</th>
              <!-- <th scope="col">Type</th> -->
              <th scope="col">Title</th>
              <!-- <th scope="col">Passengers</th> -->
              <th scope="col">Order ID</th>
              <!-- <th scope="col">Status</th> -->
              <th scope="col">Total Price</th>
              <th scope="col" v-if="!cart.is_closed">Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in cart.items" v-bind:key="item.id" :class="item.meta.is_refunded ? 'refunded' : ''">
              <td>{{ item.id }}</td>
              <!-- <td>{{ getItemType(item) }}</td> -->
              <td>{{ item.meta.title }} <span v-if="item.options.pay_later">🏠</span></td>
              <!-- <td v-html="getPassengersForHuman(item)">
                {{ getItemType(item) }}
              </td> -->
              <td>
                <a
                  v-if="item.meta.order_id"
                  @click.prevent="$router.push(`/orders/${item.meta.order_id}`)"
                  class="cursor-pointer"
                  >{{ item.meta.order_id }}</a
                >
                <a
                  v-if="item.meta.gift_id"
                  @click.prevent="$router.push(`/gifts/${item.meta.gift_id}`)"
                  class="cursor-pointer"
                  >{{ item.meta.gift_id }}</a
                >
              </td>
              <!-- <td>{{ item.meta.gift_status || "-" }}</td> -->
              <td>{{ item.meta.total_price }}</td>
              <td v-if="!cart.is_closed">
                <button
                  class="btn btn-sm btn-success mr-2"
                  title="Product quantity"
                  @click.prevent="showCopyModal(item.id)"
                  v-if="item.type == 'gift' && itemIsNotCopied(index)"
                >
                  <i class="fa fa-copy"></i>
                </button>
                <button
                  class="btn btn-sm btn-danger mr-2"
                  title="Delete"
                  @click.prevent="deleteItem(item.id)"
                >
                  <i class="fa fa-trash"></i>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="float-right w-25 mt-3 mb-4 mr-2">
        <table class="w-100">
          <tr>
            <td>Number of Items</td>
            <td class="font-weight-bold">{{ cart.items.length }}</td>
          </tr>
          <tr v-if="cart.meta['delivery_cost']">
            <td>Delivery Cost</td>
            <td class="font-weight-bold">
              {{ `${cart.meta["delivery_cost"]} AED` }}
            </td>
          </tr>
          <!-- <tr>
            <td>Additional Cost</td>
            <td class="font-weight-bold">0.00 AED</td>
          </tr>
          <tr>
            <td>Promotion code discount</td>
            <td class="font-weight-bold">
              {{ `${cart.promotion_amount} AED` }}
            </td>
          </tr> -->
          <tr>
            <td>Total Price</td>
            <td class="font-weight-bold">
              {{ `${cart.total_price} AED` }}
            </td>
          </tr>
          <tr v-if="cart.meta['vat']">
            <td>VAT(5%)</td>
            <td class="font-weight-bold">{{ `${cart.meta["vat"]} AED` }}</td>
          </tr>
          <tr v-if="cart.meta['payable']">
            <td class="text-success">Payable</td>
            <td class="font-weight-bold text-success">
              {{ cart.total_price + " " + cart.currency }}
            </td>
          </tr>
          <!-- <tr>
            <td>Discount</td>
            <td class="font-weight-bold">0.00 AED</td>
          </tr>
          <tr>
            <td>Transaction Amount</td>
            <td class="font-weight-bold">0.00 AED</td>
          </tr> -->
        </table>
      </div>

      <div class="cart-confirm w-50 pb-4" v-if="!this.cart.is_closed">
        <!-- <button
          @click.prevent="$modal.show('confirm-modal')"
          class="btn btn-success d-inline-block"
        >
          Confirm
        </button> -->

        <button
          @click.prevent="$modal.show('logs-modal')"
          class="btn btn-warning d-inline-block ml-2"
        >
          Logs
        </button>
      </div>
    </div>

    <modal name="copy-modal" height="auto">
      <div class="p-4">
        <button @click="$modal.hide('copy-modal')" class="modal-close-button">
          ❌
        </button>
        <div class="h3 mb-4">Set the quantity of the product</div>
        <input
          v-model="cpNewQuantity"
          type="number"
          min="1"
          max="100"
          class="form-control"
        />
        <button class="btn btn-success w-100 mt-3" @click="copyItem">
          Submit
        </button>
      </div>
    </modal>

    <modal name="confirm-modal" height="auto" v-if="loaded">
      <confirm-modal :cart="cart" @done="loadData()"></confirm-modal>
    </modal>

    <modal name="logs-modal" height="auto" v-if="loaded">
      <logs-modal :id="cart.id"></logs-modal>
    </modal>

    <div class="mt-5 pt-5">
      <loading v-if="!loaded"></loading>
    </div>
  </div>
</template>

<script>
import ConfirmModal from "../components/ViewComponents/Carts/ConfirmModal.vue";
import CartLogsModal from "../components/ViewComponents/Carts/Logs.vue";
import { config } from "../helpers";

export default {
  name: "Cart",
  components: {
    "confirm-modal": ConfirmModal,
    "logs-modal": CartLogsModal,
  },
  data() {
    return {
      id: this.$route.params.id,
      loaded: false,
      cart: null,

      selectedPaymentMethod: null,

      cpItemId: null,
      cpNewQuantity: null,
    };
  },

  mounted() {
    this.url = config("BACKEND_ROOT") + "/admin/carts";
    this.loadData();
  },

  methods: {
    loadData() {
      axios.get(`${this.url}/${this.id}`).then((response) => {
        this.cart = response.data.data;
        this.loaded = true;
      });
    },

    showCopyModal(itemId) {
      this.cpItemId = itemId;
      this.cpNewQuantity = 1;
      this.$modal.show("copy-modal");
    },

    copyItem() {
      if (this.cpNewQuantity == 1) {
        return this.$modal.hide("copy-modal");
      }

      this.loaded = false;

      axios
        .post(`${this.url}/${this.id}/items/${this.cpItemId}/copy`, {
          copies: this.cpNewQuantity - 1,
        })
        .then((response) => {
          this.$modal.hide("copy-modal");
          this.loadData();
        })
        .catch((error) => {
          let errorMessage =
            Object.values(error.response.data.errors).flat()[0] ??
            error.response.data?.meta?.message;
          alert(errorMessage);
        });
    },

    deleteItem(itemId) {
      if (confirm("Are you sure about deleting this item?")) {
        this.loaded = false;
        axios
          .delete(`${this.url}/${this.id}/items/${itemId}`)
          .then((response) => {
            this.loadData();
          })
          .catch((error) => {
            let errorMessage =
              Object.values(error.response.data.errors).flat()[0] ??
              error.response.data?.meta?.message;
            alert(errorMessage);
          });
      }
    },

    formatDate(date) {
      if (!date) return null;

      date = new Date(date);

      return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate() +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes()
      );
    },

    getItemType(item) {
      if (item.type == "experience") {
        return item.type;
      }

      return `${item.type} (${item.options.gift_format})`;
    },

    getPassengersForHuman(item) {
      let passengers = item.options.dd_passengers;

      if (passengers == undefined) {
        return "-";
      }

      if (!isNaN(passengers)) {
        return passengers;
      }

      let output = [];

      for (const passenger of passengers) {
        let price = "";

        if (
          item.meta.unit_price &&
          typeof item.meta.unit_price === "object" &&
          passenger.id in item.meta.unit_price
        ) {
          price =
            ": " +
            item.meta.unit_price[passenger.id] * passenger.count +
            " AED";
        }

        output.push(`${passenger.count} x ${passenger.title} ${price}`);
      }

      return output.join("<br>");
    },

    getOrderOrGiftId(item) {
      if (item.meta.order_id) {
        return `<a @click="$router.push('/orders/${item.meta.order_id}')">${item.meta.order_id}</a>`;
      }

      if (item.meta.gift_id) {
        return `<a @click="$router.push('/gifts/${item.meta.gift_id}')">${item.meta.gift_id}</a>`;
      }

      return "<a>-</a>";
    },

    itemIsNotCopied(itemIndex) {
      if (itemIndex == 0) return true;
      return (
        JSON.stringify(this.cart.items[itemIndex].options) !=
        JSON.stringify(this.cart.items[itemIndex - 1].options)
      );
    },
  },

  computed: {
    getStatusBadge() {
      if (this.cart.is_closed) {
        return '<span class="badge badge-success">Purchased</span>';
      } else {
        return '<span class="badge badge-warning">Open</span>';
      }
    },
  },
};
</script>

<style scoped>
.cart-confirm {
  position: fixed;
  bottom: 0;
}

.refunded {
  background: rgb(147, 51, 51);
  color: #fff;
}
</style>
